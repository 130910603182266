import * as servicomTypes from "./servicomTypes";

const initialState = {
  isLoading: false,
  servicom: {},
  servicomContact: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case servicomTypes?.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case servicomTypes?.SERVICOM:
      return {
        ...state,
        servicom: action.payload,
      };
    case servicomTypes?.SERVICOM_CONTACT:
      return {
        ...state,
        servicomContact: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
