import { BASE_URL } from "../constant/config";

export const httpRequest = async (params) => {
  try {
    const { method, body, url, isFormData = false } = params;

    if (!url) throw new Error("Url not set");

    if (typeof url !== "string") throw new Error("Url must be a string");

    const headers = getHeaders();

    const options = {
      method: method || "GET",
      headers: headers,
    };

    if (body) options.body = isFormData ? body : JSON.stringify(body);

    const res = await fetch(`${BASE_URL}/${url}`);

    if (res.status === 401) {
      throw new Error("Something went wrong");
    }

    const response = await res.text();

    const result = JSON.parse(response);

    return result;
  } catch (error) {}
};

const getHeaders = () => {
  let headers = {
    Accept: "application/json",
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  return headers;
};
