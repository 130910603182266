import { combineReducers } from "redux";
import aboutUs from "./aboutUs/aboutUsReducer";
import news from "./news/NewsAndMediaReducer";
import programme from "./programme/ProgrammeReducer";
import servicom from "./servicom/servicomReducer";

export default combineReducers({
  about: aboutUs,
  news: news,
  programme: programme,
  servicom: servicom,
});

