import { CircularProgress } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import BottomPGDECard from "../pgde/BottomPGDECard";

function GeneralAdee({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  return (
    <div className="general_adee_wrapper">
      {loading ? (
        <div className="center-loading">
          <CircularProgress sx={{ width: "20px" }} />
        </div>
      ) : (
        /* <h4 style={{ margin: "20px 0px 10px" }}>Objectives of the Program:</h4>

      <p
        style={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: " #505050",
        }}
      >
        The programme is hosted under the Green Teacher Nigeria (GTN) in
        collaboration with the Common wealth of Learning (COL). The NTI-COL
        Green Teacher programme is a unique capacity-building Distance Learning
        programme aimed at helping the student to:
      </p>
      <ol>
        <li>
          Enhance their appreciation of:
          <ul>
            <li>
              The environment and generating deep interest and concern for
              environmental issues;
            </li>
            <li>
              The relationship and connection of political, socio-economic and
              cultural activities as they affect environment
            </li>
            <li>
              Environmental Education (EE) – theory, approaches and methods
            </li>
          </ul>
        </li>
        <li>Strengthening the students skills</li>
        <li>
          Disseminating among young learners, the knowledge and skills on the
          environment and how to dispose waste, conserve and protect it;
        </li>
        <li>
          Fostering appropriate values and attitudes among young learners for
          the protection and improving the environment by individuals, different
          social groups and communities in the context of sustainable
          development,
        </li>
      </ol> */
        <div
          className="adee_wrapper_content"
          dangerouslySetInnerHTML={{
            __html: content?.general_info_body || "",
          }}
        />
      )}
      <BottomPGDECard
        fee={content?.application_fee_amount}
        isPhone={isPhone}
        semester={content?.course_semester_duration}
        year={content?.course_semester_years}
        note={content?.note}
      />
    </div>
  );
}

export default GeneralAdee;
