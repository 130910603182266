import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import PageContainer from "../../PageContainer";
const Courses = () => {
  const tableHead = ["S/N", "COURSE TITLE"];
  const [active, setActive] = useState("nti-programmes");
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [mobileActive, setMobileActive] = useState(0);

  // setting the active setion style
  const classNameFunc = ({ isActive }) =>
    isActive ? "listItems listItems-active" : "listItems";

  const history = useLocation();

  const [courses, setCourses] = useState([]);

  const leftLinks = [
    {
      title: "NTI Programmes",
      link: "/programmes/nti-programmes",
    },
    { title: "PGDE", link: "/programmes/pgde" },

    {
      title: "B.ED",
      link: "/programmes/bed",
    },
    { title: "NCE", link: "/programmes/nce" },
    { title: "ADE", link: "/programmes/ade" },
    { title: "ADEE", link: "/programmes/adee" },
    { title: "PTTP", link: "/programmes/pttp" },
    { title: "CPD", link: "/programmes/cpd" },
    { title: "Capacity Building", link: "/programmes/capacity-building" },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#46C35F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFDFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const getCourses = () => {
    fetch("https://cms.nti.edu.ng/api/nce-courses")
      .then((res) => res.json())
      .then((res) => {
        // setCourses(res.courses)
        let div = document.createElement("div");

        div.innerHTML = res.courses;

        let list = div.getElementsByTagName("li");

        let data = [];

        for (let i = 0; i < list.length; i++) {
          data.push({ name: list[i].innerText });
        }

        setCourses(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>NCE Courses | National Teachers' Institute Kaduna</title>
      </Helmet>

      <div id="document">
        {isPhone ? (
          <>
            <div>
              <Grid container>
                <Grid container>
                  <div className="mobile_arrow_wrapper">
                    <button
                      disabled={mobileActive <= 0}
                      onClick={() => {}}
                      className="mobile-arrow-btn"
                    >
                      <ArrowBackIcon
                        style={{
                          color: mobileActive <= 0 ? "#D0E3F2" : "#4F9D6B",
                        }}
                      />
                    </button>

                    <p
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#46C35F",
                      }}
                    >
                      {leftLinks[mobileActive]?.title}
                    </p>

                    <button
                      onClick={() => {}}
                      disabled={mobileActive >= leftLinks.length - 1}
                      className="mobile-arrow-btn"
                    >
                      <ArrowForwardIcon
                        style={{
                          color:
                            mobileActive >= leftLinks.length - 1
                              ? "#D0E3F2"
                              : "#4F9D6B",
                        }}
                      />
                    </button>
                  </div>
                  <h4>NCE Courses</h4>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {tableHead &&
                              tableHead.map(
                                (td, key) =>
                                  Boolean(td) && (
                                    <StyledTableCell
                                      key={key}
                                      style={{
                                        minWidth: 170,
                                      }}
                                    >
                                      {td}
                                    </StyledTableCell>
                                  )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {courses.map(({ name }, key) => (
                            <StyledTableRow key={key}>
                              <StyledTableCell
                                style={{
                                  minWidth: 170,
                                }}
                                component="th"
                                scope="row"
                              >
                                {key + 1}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {name}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <Grid
              container
              sx={{ margin: "5% 0", padding: "0px 92px", display: "flex" }}
            >
              <Grid item style={{ marginTop: "1rem", flex: 1 }}>
                <div id="floating-menu">
                  {leftLinks.map(({ link, title }, key) => {
                    return (
                      <NavLink
                        className={classNameFunc}
                        to={link}
                        key={key}
                        onClick={() => {
                          setActive(
                            history?.pathname?.split("/")[2] || "nti-programmes"
                          );
                        }}
                      >
                        {title}
                      </NavLink>
                    );
                  })}
                </div>
              </Grid>

              <Grid item style={{ flex: 4 }} className="about-components">
                <h4>NCE Courses</h4>

                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {tableHead &&
                            tableHead.map(
                              (td, key) =>
                                Boolean(td) && (
                                  <StyledTableCell
                                    key={key}
                                    style={{
                                      minWidth: 170,
                                    }}
                                  >
                                    {td}
                                  </StyledTableCell>
                                )
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {courses.map(({ name }, key) => (
                          <StyledTableRow key={key}>
                            <StyledTableCell
                              style={{
                                minWidth: 170,
                              }}
                              component="th"
                              scope="row"
                            >
                              {key + 1}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {name}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </PageContainer>
  );
};

export default Courses;
