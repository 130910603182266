import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getPgdeContent } from "../../redux/programme/ProgrammeAction";
import BottomPGDECard from "./pgde/BottomPGDECard";

function PaymentMethods({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  // const { loading, pgde } = useSelector((state) => state.programme);

  // const [active, setActive] = useState();

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getPgdeContent());
  // }, [dispatch]);
  return (
    <div
      className="payment_pgde_wrapper"
      dangerouslySetInnerHTML={{
        __html: content?.paymentMethod || "",
      }}
    />
    // <div className="payment_pgde_wrapper">
    //   <Box
    //     sx={{
    //       marginTop: "2rem",
    //     }}
    //   >
    //     <h4>Online Payment Methods:</h4>
    //     <Box
    //       sx={{ display: "flex", flexDirection: isPhone ? "column" : "row" }}
    //     >
    //       <ul>
    //         <li>
    //           Go to{" "}
    //           <a
    //             href="http://my.nti.edu.ng"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             my.nti.edu.ng
    //           </a>
    //         </li>
    //         <li>Apply for Admission</li>
    //         <li>Fill the Application form</li>
    //         <li>Generate RRR invoice</li>
    //         <li>Save the generated RRR invoice or print it</li>
    //       </ul>
    //       <ul>
    //         <li>
    //           Select Pay via Remita/ or Go online through Remita
    //           https://remita.net/ (Select Pay An Invoice)
    //         </li>
    //         <li>Verify the Payment</li>
    //         <li>Continue your Application</li>
    //       </ul>
    //     </Box>
    //   </Box>
    //   <Box>
    //     <h4 style={{ marginTop: "3rem" }}>Bank Payment Methods</h4>
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: isPhone ? "column" : "row",
    //       }}
    //     >
    //       <ul>
    //         <li>
    //           Go to{" "}
    //           <a
    //             href="http://my.nti.edu.ng"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             my.nti.edu.ng
    //           </a>
    //         </li>
    //         <li>Apply for Admission</li>
    //         <li>Fill the Application form</li>
    //         <li>Generate RRR invoice</li>
    //         <li>Save the generated RRR invoice or print it</li>
    //       </ul>
    //       <ul>
    //         <li>Go to the Bank and make payment with the invoice </li>
    //         <li>Login on the account to Verify the payment</li>
    //       </ul>
    //     </Box>
    //   </Box>
    //   <BottomPGDECard
    //     fee={5000}
    //     isPhone={isPhone}
    //     semester={2}
    //     year={"15months"}
    //   />
    // </div>
  );
}

export default PaymentMethods;
