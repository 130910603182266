import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import PageContainer from "../component/PageContainer";
import { Helmet } from "react-helmet-async";
import Page404Image from "../assets/images/404.svg";
import PageImage from "../assets/images/404-page-image.svg";

const Error404 = (props) => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  const mainStyles = {
    container: {
      padding: "50px",
    },
    phoneContainer: {
      paddingLeft: "25px",
      paddingRight: "25px",
      marginTop: "25px",
    },
    subContainer: {
      backgroundColor: "#fff",
      padding: "70px",
      margin: "30px 0",
      boxShadow: "0px 0px 5px 2px #E8E8E8",
    },
  };

  return (
    <>
      <Container
        style={isPhone ? mainStyles.phoneContainer : mainStyles.container}
      >
        <Helmet>
          <title>404 - Page Not Found </title>
        </Helmet>

        <Box
          className="slide-box"
          style={isPhone ? {} : { margin: "20px 100px" }}
        >
          <Paper elevation={5}>
            <Grid
              className="log-div"
              style={{
                ...mainStyles.subContainer,
                ...{
                  padding: "30px 50px",
                  flexDirection: isPhone ? "column" : "row",
                },
              }}
            >
              <Grid
                className="log-grid"
                item
                xs={12}
                sm={6}
                style={{
                  borderRadius: "10px 0 0 10px",
                  padding: "30px 60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="page" src={PageImage} />
              </Grid>

              <Grid item xs={12} sm={6} style={{ padding: "20px 15px" }}>
                <Typography
                  className="login-main"
                  style={{
                    fontWeight: 500,
                    fontSize: "20px",
                    margin: "5%",
                    marginBottom: "0",
                    textAlign: "center",
                  }}
                >
                  It seems you are lost
                </Typography>

                <img
                  alt="404"
                  src={Page404Image}
                  style={{ margin: "-2rem auto", width: "100%" }}
                />

                <Typography
                  className="log-sub"
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#9F9F9F",
                    textAlign: "center",
                  }}
                >
                  The page you’re looking for was not found <br />
                  Good thing i know the right way
                </Typography>
                <Grid item xs={12} style={mainStyles.inputGrid}>
                  <Box mb={6}>
                    <Button
                      onClick={() => (window.location.href = "/")}
                      fullWidth
                      style={{ height: "50px" }}
                      variant="contained"
                      color="success"
                    >
                      GO BACK HOME
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </>
  );
};
export default Error404;
