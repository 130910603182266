import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../assets/images/Logo.svg";
import Arrow_Right from "../assets/images/arrow_right.svg";
import Email from "../assets/icon/home_message_white_icon.svg";
import Twitter from "../assets/icon/twitter_icon.svg";
import Facebook from "../assets/icon/facebook_icon.svg";
import Linkedln from "../assets/icon/linkedIn_icon.svg";
import Youtube from "../assets/icon/youtube_icon.svg";

function Footer() {
  const { news } = useSelector((state) => state.news);
  const navigate = useNavigate();

  const links = [
    {
      title: "Privacy Policy",
      link: "https://my.nti.edu.ng/",
    },
    {
      title: "Contact Us",
      link: "/contact-us",
    },
    {
      title: "Terms of use",
      link: "https://my.nti.edu.ng/",
    },

    {
      title: "News & Media",
      link: "/news",
    },
    {
      title: "Student Portal",
      link: "https://my.nti.edu.ng",
    },
  ];
  const social = [
    {
      title: "Twitter icon",
      icon: Twitter,
      link: "https://twitter.com/ntiedung",
    },
    {
      title: "Facebook icon",
      icon: Facebook,
      link: "https://www.facebook.com/ntiedung",
    },
    {
      title: "Youtube icon",
      icon: Youtube,
      link: "",
    },
    {
      title: "Linkedln icon",
      icon: Linkedln,
      link: "",
    },
  ];
  return (
    <footer className="footer">
      <div className="footer_wrapper">
        <div className="footer_about">
          <h4>ABOUT NTI NIGERIA</h4>
          <img src={Logo} alt="logo" />
          <p>
            The National Teachers' Institute was setup to serve as a Distance
            Learning Educational Institution in order to provide additional
            means of tackling the challenges facing Teacher Education in Nigeria
          </p>
        </div>
        <div className="footer_news">
          <h4>RECENT NEWS</h4>
          {news?.slice(0, 3)?.map((news, key) => (
            <div
              className="footer_news_wrapper"
              key={key}
              onClick={() => {
                navigate(`/news/${news?.id}`, {
                  news,
                });
              }}
            >
              <img src={Arrow_Right} alt="arrow" />
              <p>{news?.title.substring(0, 50)}...</p>
            </div>
          ))}
        </div>
        <div className="footer_links">
          <h4>OTHER LINKS</h4>
          <ul>
            {links.map(({ link, title }, key) =>
              link?.includes("https") ? (
                <a href={link} key={key} style={{ color: "#fff" }}>
                  <li>
                    <img src={Arrow_Right} alt="arrow" />
                    {title}
                  </li>
                </a>
              ) : (
                <Link to={link} key={key} style={{ color: "#fff" }}>
                  <li>
                    <img src={Arrow_Right} alt="arrow" />
                    {title}
                  </li>
                </Link>
              )
            )}
          </ul>
        </div>
        <div className="footer_newsletter">
          <h4>NEWSLETTER</h4>
          <p style={{ marginTop: "18px" }}>
            Signup to always recieve updates about latest properties
          </p>
          <div className="footer_input_wrapper">
            <img src={Email} alt="" />{" "}
            <input type="text" placeholder="Enter your email" />
          </div>
          <button className="btn">SUBSCRIBE</button>
        </div>
        <div className="footer_copyright">
          <p>
            &copy; {new Date().getFullYear()} National Teachers Institute
            Nigeria | All Rights Reserved | Powered by{" "}
            <a
              href="https://www.omniswift.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              OmniSwift
            </a>
          </p>
          <div className="footer_social_icons">
            {social.map((icon, key) => (
              <a
                href={icon.link}
                target="_blank"
                key={key}
                className="footer-social_icon_link"
              >
                <img
                  src={icon.icon}
                  alt={icon.title}
                  style={{ marginRight: "24px" }}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
