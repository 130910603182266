import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageContainer from "../PageContainer";
import IMG from "../../assets/images/news_background_image.png";
import { Box, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getNewsContent } from "../../redux/news/NewsAndMediaAction";
import moment from "moment";

function NewsDetails() {
  const params = useParams();

  console.log(params);

  const history = useLocation();

  console.log(history);

  const { isLoading, news } = useSelector((state) => state.news);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const singleNews = news?.filter((ne) => ne?.id === +params?.id);

  console.log(singleNews);

  // useEffect(() => {
  //   dispatch(getNewsContent());
  // }, [dispatch]);
  return (
    <PageContainer>
      <div className="news-detail-wrapper">
        <h1>{singleNews[0]?.title}</h1>
        <div className="news-details-date-wrapper">
          <p
            style={{
              textTransform: "capitalize",
            }}
          >
            {singleNews[0]?.category}
          </p>
          <p>
            {singleNews[0]?.created_at &&
              moment(singleNews[0]?.created_at).format("Do MMMM YYYY")}
          </p>
        </div>

        <img
          src={`https://www.nti.edu.ng/static/media/Logo.b5d023f99a9bae47a2886b993915b4d9.svg`}
          alt="news"
          width={"60%"}
          height={"320px"}
        />

        {/* <p>{singleNews[0]?.paragraph}</p> */}

        <p
          dangerouslySetInnerHTML={{
            __html: singleNews[0]?.paragraph || "",
          }}
        />

        <Divider
          sx={{
            border: "0.5px solid #BDBDBD",
            margin: "2rem 0rem",
          }}
        />

        <div className="related-news-wrapper">
          <h4>Related news</h4>
          <div className="news-details-cards-wrapper">
            {news?.slice(0, 2)?.map((ne, key) => (
              <Box
                key={key}
                style={{
                  cursor: "pointer",
                }}
                className="news-details-cards"
                onClick={() => {
                  navigate(`/news/${ne?.id}`, {
                    ne,
                  });
                }}
              >
                <img
                  src={`https://cms.nti.edu.ng/public/images/${ne?.image}`}
                  alt="news"
                />
                <div className="news-details-content-wrapper">
                  <div className="news-details-content-date">
                    <p>{ne?.category}</p>
                    <p>
                      {" "}
                      {news?.created_at &&
                        moment(news?.created_at).format("Do MMMM  YYYY")}
                    </p>
                  </div>
                  <h4>{ne?.title.substring(0, 50)}...</h4>
                </div>
              </Box>
            ))}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default NewsDetails;
