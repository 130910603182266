import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFunctionContent } from "../../redux/aboutUs/aboutUsAction";

function Functions() {
  const { functionContent } = useSelector((state) => state.about);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFunctionContent());
  }, [dispatch]);

  console.log(functionContent);

  return (
    <section
      className="about_functions"
      dangerouslySetInnerHTML={{
        __html: functionContent?.our_function_page || "",
      }}
    />
    // <section className="about_functions">
    //   <h4>Functions of the Institute</h4>
    //   <p>The enabling law mandates the institute to:</p>

    //   <ol>
    //     <li>upgrade under-qualified and untrained teachers.</li>
    //     <li>
    //       conduct post-graduate courses and examinations in education for
    //       graduate teachers.
    //     </li>
    //     <li>provide refresher and other upgrading courses for teachers.</li>
    //     <li>
    //       organize workshops, seminars and conferences, which would assist in
    //       the improvement of teachers.
    //     </li>
    //     <li>conduct examinations.</li>
    //     <li>
    //       carry out research in conjunction with other bodies on any matter
    //       relevant to educational development in the country.
    //     </li>
    //     <li>
    //       formulate policies and initiate programmes at all levels of education
    //       designed to improve by way of research the quality and content of
    //       education in Nigeria.
    //     </li>
    //     <li>
    //       assess from time to time the training programmes offered by
    //       Institutions controlled by or associated with the Institute with a
    //       view to ascertaining the professional competence of those
    //       Institutions.
    //     </li>
    //     <li>
    //       offer such assistance, either alone or in co-operation with
    //       educational bodies as may be required by the Institutions controlled
    //       by or associated with the Institute.
    //     </li>
    //     <li>
    //       foster and enhance international co-operation in the education of
    //       teachers; and perform such other functions as are necessary or
    //       expedient for the full discharge of all the functions of the Council
    //       under this Decree.
    //     </li>
    //   </ol>
    // </section>
  );
}

export default Functions;
