import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { lazy, Suspense } from "react";
import { Box, LinearProgress } from "@mui/material";
import Loader from "../component/Loader";
import withRouter from "../component/withRouter";

// const Home = lazy(() => import("../pages/Home"));
// const AboutUS = lazy(() => import("../pages/AboutUS"));
// const ContactUS = lazy(() => import("../pages/ContactUs"));
// const News = lazy(() => import("../pages/News"));
// const Programme = lazy(() => import("../pages/Programme"));

import Home from "../pages/Home";
import AboutUS from "../pages/AboutUS";
import ContactUs from "../pages/ContactUs";
import News from "../pages/News";
import Programme from "../pages/Programme";
import Error404 from "../pages/Error404";
import NewsDetails from "../component/news/NewsDetails";
import Servicom from "../pages/Servicom";
import Courses from "../component/programme/nce/Courses";
import Reports from "../pages/reports";
import ReportData from "../pages/report-data";

const ScrollToTop = () => {
  const history = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.pathname]);

  return null;
};

const ResetScroll = withRouter(ScrollToTop);

function routes() {
  return (
    <>
      <ResetScroll />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUS />} />
        <Route path="/about-us/:section" element={<AboutUS />} />
        <Route path="/programmes" element={<Programme />} />
        <Route path="/programmes/:section" element={<Programme />} />
        <Route path="/programmes/nce/courses" element={<Courses />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewsDetails />} />
        <Route path="/servicom" element={<Servicom />} />
        <Route path="/servicom/:section" element={<Servicom />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/reports" element={<Reports />} />
        {/* <Route path="/report-data" element={<ReportData />} /> */}

        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default routes;
