import * as AboutUsTypes from "./aboutUsTypes";

const initialState = {
  isLoading: false,
  structure: {},
  functionContent: {},
  governance: {},
  achievement: {},
  centers: {},
  management: {},
  zonalOffices: [],
  northEast: {},
  northWest: {},
  southEast: {},
  southSouth: {},
  southWest: {},
  sliderImage: {},
  aboutUs: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AboutUsTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case AboutUsTypes.GET_STUCTURE_PAGE_CONTENT:
      return {
        ...state,
        structure: action.payload,
      };
    case AboutUsTypes.GET_FUNCTION_PAGE_CONTENT:
      return {
        ...state,
        functionContent: action.payload,
      };
    case AboutUsTypes.GET_GOVERNANCE_PAGE_CONTENT:
      return {
        ...state,
        governance: action.payload,
      };
    case AboutUsTypes.GET_ACHIEVEMNT_PAGE_CONTENT:
      return {
        ...state,
        achievement: action.payload,
      };
    case AboutUsTypes.GET_CENTERS_PAGE_CONTENT:
      return {
        ...state,
        centers: action.payload,
      };
    case AboutUsTypes.GET_MANAGEMENT_PAGE_CONTENT:
      return {
        ...state,
        management: action.payload,
      };
    case AboutUsTypes?.GET_ZONAL_OFFICES:
      return {
        ...state,
        zonalOffices: action.payload,
      };
    case AboutUsTypes.GET_NORTH_EAST_PAGE_CONTENT:
      return {
        ...state,
        northEast: action.payload,
      };
    case AboutUsTypes.GET_NORTH_WEST_PAGE_CONTENT:
      return {
        ...state,
        northWest: action.payload,
      };
    case AboutUsTypes.GET_SOUTH_EAST_PAGE_CONTENT:
      return {
        ...state,
        southEast: action.payload,
      };
    case AboutUsTypes.GET_SOUTH_SOUTH_PAGE_CONTENT:
      return {
        ...state,
        southSouth: action.payload,
      };
    case AboutUsTypes.GET_SOUTH_WEST_PAGE_CONTENT:
      return {
        ...state,
        southWest: action.payload,
      };

    case AboutUsTypes.GET_ABOUT_US:
      return {
        ...state,
        aboutUs: action.payload,
      };

    case AboutUsTypes.SLIDER_CONTENT:
      return {
        ...state,
        sliderImage: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
