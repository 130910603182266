import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PageContainer from "../component/PageContainer";
import { NavLink, useLocation } from "react-router-dom";
import AboutNTI from "../component/aboutUs/AboutNTI";
import Structure from "../component/aboutUs/Structure";
import Functions from "../component/aboutUs/Functions";
import Governance from "../component/aboutUs/Governance";
import Achievement from "../component/aboutUs/Achievement";
import Centers from "../component/aboutUs/Centers";
import Management from "../component/aboutUs/Management";
import ZonalOffices from "../component/aboutUs/ZonalOffices";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../component/Loader";
import { Helmet } from "react-helmet-async";
import $ from "jquery";
import { aboutUs } from "../redux/aboutUs/aboutUsAction";

function AboutUS() {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [mobileActive, setMobileActive] = useState(0);

  // setting the active setion style
  const classNameFunc = ({ isActive }) =>
    isActive ? "listItems listItems-active" : "listItems";

  const history = useLocation();

  const dispatch = useDispatch();

  const leftLinks = [
    {
      title: "About NTI",
      link: "/about-us/about-nti",
    },
    { title: "Our Structure", link: "/about-us/structure" },

    {
      title: "Our Functions",
      link: "/about-us/functions",
    },
    { title: "NTI Management", link: "/about-us/management" },
    { title: "Governance", link: "/about-us/governance" },
    { title: "Our Achievements", link: "/about-us/achievement" },
    { title: "Specialized Centers", link: "/about-us/centers" },

    { title: "Zonal & State Offices", link: "/about-us/zonal-offices" },
  ];

  const getSection = () => {
    switch (mobileActive) {
      case 0:
        return <AboutNTI />;
      case 1:
        return <Structure />;
      case 2:
        return <Functions />;
      case 3:
        return <Management />;
      case 4:
        return <Governance />;
      case 5:
        return <Achievement />;
      case 6:
        return <Centers />;
      case 7:
        return <ZonalOffices />;
      default:
        <></>;
    }
  };

  const changeMobileActive = (type) => {
    // type can be 0 or 1 where 0 means previous and 1 means forward
    setMobileActive(type === 0 ? mobileActive - 1 : mobileActive + 1);
  };
  const [active, setActive] = useState("about-nti");

  useEffect(() => {
    dispatch(aboutUs());
  }, []);

  useEffect(() => {
    setActive(history?.pathname?.split("/")[2] || "about-nti");
    if (history?.pathname?.split("/")[2]) {
      $("html, body").animate(
        {
          scrollTop: $("#document").offset().top,
        },
        1000
      );
    }
  }, [history.pathname]);

  return (
    <PageContainer>
      <Helmet>
        <title>About Us | National Teachers' Institute Kaduna</title>
      </Helmet>
      <div id="document">
        {isPhone ? (
          <>
            <div>
              <Grid container>
                <Grid container>
                  <div className="mobile_arrow_wrapper">
                    <button
                      disabled={mobileActive <= 0}
                      onClick={() => changeMobileActive(0)}
                      className="mobile-arrow-btn"
                    >
                      <ArrowBackIcon
                        style={{
                          color: mobileActive <= 0 ? "#D0E3F2" : "#4F9D6B",
                          cursor: "pointer",
                        }}
                      />
                    </button>

                    <p
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#46C35F",
                      }}
                    >
                      {leftLinks[mobileActive]?.title}
                    </p>

                    <button
                      onClick={() => changeMobileActive(1)}
                      disabled={mobileActive >= leftLinks.length - 1}
                      className="mobile-arrow-btn"
                    >
                      <ArrowForwardIcon
                        style={{
                          color:
                            mobileActive >= leftLinks.length - 1
                              ? "#D0E3F2"
                              : "#4F9D6B",
                          cursor: "pointer",
                        }}
                      />
                    </button>
                  </div>
                  {getSection()}
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <Grid sx={{ margin: "5% 0", padding: "0px 92px", display: "flex" }}>
              <Grid item style={{ marginTop: "1rem", flex: 1 }}>
                <div id="floating-menu">
                  {leftLinks.map(({ link, title }, key) => {
                    return (
                      <NavLink
                        className={classNameFunc}
                        to={link}
                        key={key}
                        onClick={() => {
                          setActive(
                            history?.pathname?.split("/")[2] || "about-nti"
                          );
                        }}
                      >
                        {title}
                      </NavLink>
                    );
                  })}
                </div>
              </Grid>

              <Grid item style={{ flex: 4 }} className="about-components">
                {active === "about-nti" ? (
                  <AboutNTI />
                ) : active === "structure" ? (
                  <Structure />
                ) : active === "functions" ? (
                  <Functions />
                ) : active === "governance" ? (
                  <Governance />
                ) : active === "achievement" ? (
                  <Achievement />
                ) : active === "centers" ? (
                  <Centers />
                ) : active === "management" ? (
                  <Management />
                ) : (
                  <ZonalOffices />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </PageContainer>
  );
}

export default AboutUS;
