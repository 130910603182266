import React, { useState, useEffect } from "react";
import ProgSection from "./ProgSection";
import IMG from "../../assets/images/prog_nce_img.svg";
import PaymentMethods from "./PaymentMethods";
import GeneralInfoNce from "./nce/GeneralInfoNce";
import RequirementNce from "./nce/RequirementNce";
import FeeNce from "./nce/FeeNce";
import { Grid } from "@mui/material";
import { getNceContent } from "../../redux/programme/ProgrammeAction";
import { useDispatch, useSelector } from "react-redux";

function NCE() {
  const [section, setSection] = useState("general");
  const [sectionTitle, setSectionTitle] = useState("General info");

  const [show, setShow] = useState(false);

  const { loading, nce } = useSelector((state) => state.programme);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNceContent());
  }, [dispatch]);

  return (
    <div style={{ padding: "1rem", width: "100%" }}>
      <ProgSection
        heading="Nigeria Certificate In Education (NCE)"
        image={IMG}
        setShow={setShow}
        setSection={setSection}
        section={section}
        setSectionTitle={setSectionTitle}
        show={show}
        sectionTitle={sectionTitle}
      />
      <Grid item sx={{ width: "100%" }}>
        {section === "general" ? (
          <GeneralInfoNce content={nce} loading={loading} />
        ) : section === "requirements" ? (
          <RequirementNce content={nce} loading={loading} />
        ) : section === "fees" ? (
          <FeeNce content={nce} loading={loading} />
        ) : section === "payment" ? (
          <PaymentMethods content={nce} loading={loading} />
        ) : (
          <GeneralInfoNce content={nce} loading={loading} />
        )}
      </Grid>
    </div>
  );
}

export default NCE;
