import "./App.css";
import Routes from "./routes/routes";
import "animate.css/animate.min.css";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import AuthProvider from "./component/AuthProvider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

const store = configureStore();

function App() {
  return (
    <BrowserRouter basename="/">
      <Provider store={store}>
        <HelmetProvider>
          {/* <AuthProvider> */}

          <Routes />

          {/* </AuthProvider> */}
        </HelmetProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
