import React from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

function PageContainer({ children }) {
  return (
    <div style={{ width: "100%" }}>
      <Header />
      {children}
      <Footer />
    </div>
  );
}

export default PageContainer;
