import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import "../css/AboutUs.css";
import BackgroundAbout from "../assets/images/about_us_bacImg.svg";
import BackgroundServicom from "../assets/images/BackgroundServicom.png";
import Navbar from "./Navbar";
import Programme from "../assets/images/prog_background_image.png";
import News from "../assets/images/news_background_image.png";

function GeneralHeader({ setActive, setActiveLink, activeLink }) {
  const location = useLocation();
  const isPhone = useMediaQuery({ query: "(max-width: 899px)" });
  const [backgroundImage, setBackgroundImage] = useState(BackgroundAbout);

  useEffect(() => {
    setBackgroundImage(
      location.pathname.includes("about-us")
        ? BackgroundAbout
        : location.pathname.includes("programmes")
        ? Programme
        : location.pathname.includes("news")
        ? News
        : location.pathname.includes("contact-us")
        ? News
        : location.pathname.includes("servicom")
        ? BackgroundServicom
        : BackgroundAbout
    );
  }, [location.pathname]);

  return (
    <header
      className="header"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "auto",
      }}
    >
      <div className="header_wrapper">
        <Navbar
          setActive={setActive}
          setActiveLink={setActiveLink}
          activeLink={activeLink}
        />
        <div
          className="about_header_wrapper"
          style={{
            padding: isPhone ? "0px" : "100px 170px",
          }}
        >
          <h1>
            {location.pathname.includes("about-us")
              ? "About Us"
              : location.pathname.includes("programmes")
              ? "Programmes"
              : location.pathname.includes("news")
              ? "News and Media"
              : location.pathname.includes("contact-us")
              ? "Contact Us"
              : location.pathname.includes("servicom")
              ? "Servicom"
              : location.pathname.includes("reports")
              ? "Reports"
              : "About Us"}
          </h1>
          {location.pathname.includes("about-us") ? (
            <p>
              Welcome to the National Teachers Institute (NTI), where innovation
              meets education excellence. Explore our programs, resources, and
              community initiatives designed to shape the future of education.{" "}
            </p>
          ) : location.pathname.includes("programmes") ? (
            <p>
              Discover the diverse range of programs offered by NTI. From
              professional development courses to specialized training
              initiatives, our programs are designed to meet the evolving needs
              of educators and learners alike. Explore our offerings and embark
              on a journey of growth, innovation, and impact with NTI.{" "}
            </p>
          ) : location.pathname.includes("news") ? (
            <p>
              Stay Informed with latest News and Media Updates Explore the
              latest happenings, announcements, and insights from NTI in our
              News and Media section. From press releases to informative
              articles and multimedia content, stay connected and informed about
              our initiatives, events, and achievements.{" "}
            </p>
          ) : location.pathname.includes("servicom") ? (
            <p>
              Commitment to Service Excellence: At NTI, we prioritize
              transparency, accountability, and customer satisfaction in all our
              interactions. See how we uphold these values and ensure the
              highest standards of service delivery to our stakeholders.{" "}
            </p>
          ) : location.pathname.includes("contact-us") ? (
            <p>
              Have questions or inquiries? Reach out to NTI's dedicated team for
              assistance. Whether you're seeking information about our programs,
              partnership opportunities, or general inquiries, we're here to
              help. Contact us today and let us support you on your educational
              journey.{" "}
            </p>
          ) : location.pathname.includes("reports") ? (
            <p>
              Explore NTI's collection of reports detailing our diverse training
              initiatives. From professional development workshops to
              specialized courses, our reports offer comprehensive insights into
              the impact and outcomes of our training programs.
            </p>
          ) : null}
        </div>
      </div>
    </header>
  );
}

export default GeneralHeader;
