import { ArrowDropDown } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Img1 from "../../assets/images/prog_pgde_img.svg";
import "../../css/Programme.css";
import Fees from "./pgde/Fees";
import GeneralInfo from "./pgde/GeneralInfo";
import PaymentMethods from "./PaymentMethods";
import Requirements from "./pgde/Requirements";
import ProgSection from "./ProgSection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPgdeContent } from "../../redux/programme/ProgrammeAction";

function PGDE() {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  const { loading, pgde } = useSelector((state) => state.programme);

  // console.log(pgde);

  const [section, setSection] = useState("general");
  const [sectionTitle, setSectionTitle] = useState("General info");

  const [show, setShow] = useState(false);

  const [active, setActive] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPgdeContent());
  }, [dispatch]);

  return (
    <div style={{ padding: "1rem", width: "100%" }}>
      <ProgSection
        heading="Post Graduate Diploma in Education - PGDE"
        image={Img1}
        setShow={setShow}
        setSection={setSection}
        section={section}
        setSectionTitle={setSectionTitle}
        show={show}
        sectionTitle={sectionTitle}
      />

      <Grid item sx={{ width: "100%" }}>
        {section === "general" ? (
          <GeneralInfo data={pgde} loading={loading} />
        ) : section === "requirements" ? (
          <Requirements content={pgde} loading={loading} />
        ) : section === "fees" ? (
          <Fees content={pgde} loading={loading} />
        ) : section === "payment" ? (
          <PaymentMethods content={pgde} loading={loading} />
        ) : (
          <GeneralInfo data={pgde} loading={loading} />
        )}
      </Grid>
    </div>
  );
}

export default PGDE;
