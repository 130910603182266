import * as ProgTypes from "./ProgrammeTypes";
import { httpRequest } from "../../https/https";
import { API_ROUTES } from "../../constant/config";

export const setIsLoading = (value) => ({
  type: ProgTypes.IS_PROG_LOADING,
  payload: value,
});

export const getProgrammeContent = () => async (dispatch) => {
  try {
    // dispatch(setIsLoading(true));
    // const response = await httpRequest({
    //   url: API_ROUTES.get_program_page_main_content.route,
    //   method: API_ROUTES.get_program_page_main_content.method,
    // });
    // dispatch({
    //   type: ProgTypes?.GET_PROGRAM_MAIN_CONTENT,
    //   payload: response,
    // });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getPgdeContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_program_pgde_content.route,
      method: API_ROUTES.get_program_pgde_content.method,
    });

    console.log(response);
    dispatch({
      type: ProgTypes?.GET_PGDE_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getNceContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_program_nce_content.route,
      method: API_ROUTES.get_program_nce_content.method,
    });
    dispatch({
      type: ProgTypes?.GET_NCE_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getBedContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES?.get_program_bed_content?.route,
      method: API_ROUTES?.get_program_bed_content?.method,
    });
    dispatch({
      type: ProgTypes?.GET_BED_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getPttpContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_program_pttp_content.route,
      method: API_ROUTES.get_program_pttp_content.method,
    });
    dispatch({
      type: ProgTypes?.GET_PTTP_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getAdeContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_program_ade_content.route,
      method: API_ROUTES.get_program_ade_content.method,
    });
    dispatch({
      type: ProgTypes?.GET_ADE_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getAdeeContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_program_adee_content.route,
      method: API_ROUTES.get_program_adee_content.method,
    });
    dispatch({
      type: ProgTypes?.GET_ADEE_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getCapacityContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_program_capacity_content.route,
      method: API_ROUTES.get_program_capacity_content.method,
    });
    dispatch({
      type: ProgTypes?.GET_CAPACITY_PAGE_CONTENT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};
