import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import image from "../../assets/images/prog_cpd_img.svg";
import { getCapacityContent } from "../../redux/programme/ProgrammeAction";

function CapacityBuilding() {
  const dispatch = useDispatch();

  const { loading, capacity } = useSelector((state) => state.programme);

  useEffect(() => {
    dispatch(getCapacityContent());
  }, [dispatch]);
  return (
    <Fragment>
      {loading ? (
        <div className="center-loading">
          <CircularProgress sx={{ width: "20px" }} />
        </div>
      ) : (
        <div
          className="capacityBuilding_wrapper"
          dangerouslySetInnerHTML={{
            __html: capacity?.general_info_body || "",
          }}
        />
      )}
      {/* <h4 style={{ fontSize: "20px" }}>The Capacity Building Programmes</h4> */}
      {/* <img src={image} alt="students" className="about_section_images" /> */}

      {/* <Box sx={{ marginBottom: "2rem" }}>
        <p>
          <b>Package A-</b> Basic Teaching Methods and Techniques, Basic
          Classroom Management Techniques, Language and Communication Skills
          &ICT Training for Teachers.
        </p>

        <p>
          <b>Package B- </b> Modern Techniques of teaching the four (4) Core
          Subjects of Basic Education (English, Mathematics, Social Studies and
          Basic Science and Technology).
        </p>

        <p>
          <b>Package C-</b> Strengthening Mathematics and Science Education
          (SMASE); Learner-Centered and Activity-Based Approach; Improvisation
          of Instructional Materials
        </p>

        <p>
          <b>Package D-</b> E-Learning Education; Entrepreneurship Education;
          Special Education
        </p>
      </Box>

      <ul>
        <h4>Other specialized areas of Training:</h4>
        <li>Induction of newly recruited teachers.</li>
        <li>Teaching of the science at the SSS level.</li>
        <li>School Based Assessments</li>
        <li>
          Innovative techniques of teaching vocational and technical subjects
        </li>
        <li>School Management Skills and Record Keeping</li>
        <li>
          Effective teaching and care giving in Early Childhood Education.
        </li>
        <li>Resource Management in schools</li>
        <li>Guidance and Counseling in schools</li>
        <li>Multi-grade/Large Class Teaching Strategies</li>
        <li>Curriculum Implementation.</li>
        <li>Performance Improvement for Teachers at all levels.</li>
        <li>Teaching of English components for S.S. Teachers</li>
      </ul>

      <ul>
        <h4>Other NTI CPD Resources</h4>
        <li>CDs showing model lessons</li>
        <li>Manual for School Supervision</li>
        <li>The Teacher Education in Sub-Saharan Africa</li>
        <li>NTI/NCCE/TESSA Toolkit for Teaching Practice Supervisors</li>
        <li>Manual for Mentoring</li>
        <li>Self-Instructional Materials used in NTI Academic Programmes</li>
      </ul> */}
    </Fragment>
  );
}

export default CapacityBuilding;
