import React, { useState, useEffect } from "react";
import DirectorGeneral from "../../assets/images/Director-General.jpg";
import Pics from "../../assets/images/about_nti_temp_management.png";
import Accordion from "../Accordion";
import { bioDetails } from "../../util/tempManagementDetails";

function Management() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetch("https://cms.nti.edu.ng/api/nti-managements");
      const jsonData = await response.json();
      console.log('data',jsonData)
      setData(jsonData);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div className="about_management">
      {data.map(({ title, image, name, body }, index) => {
        const sanitizedBody = body ? body.replaceAll('[object Object]', '') : '';

        return (
          <Accordion
            key={index}
            heading={title}
            content={
              <div className="accordion_content_wrapper">
                <img
                  src={`${image}`}
                  alt={title}
                  height={350}
                  width={350}
                  style={{ marginBottom: "2rem" }}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    // e.target.src = DirectorGeneral; // Fallback image
                  }}
                />
                <h4 style={{ marginBottom: "10px" }}>{name}</h4>
                <h6
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    color: "#4F4F4F",
                    marginBottom: "20px",
                  }}
                >
                  {title}
                </h6>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "40px",
                  }}
                  dangerouslySetInnerHTML={createMarkup(sanitizedBody)}
                />
              </div>
            }
          />
        );
      })}
    </div>
  );
}

export default Management;
