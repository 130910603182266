import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReplaceSepcialChars } from "./escapeChar";

export const secList = [
  {
    name: "General info",
    section: "general",
  },
  {
    name: "Requirements",
    section: "requirements",
  },
  {
    name: "Fees",
    section: "fees",
  },
  {
    name: "Payment methods",
    section: "payment",
  },
];

export const RequirementsCard = ({ cardDetails, requireText, isPhone }) => {
  return (
    <>
      <h4 style={{ margin: "30px 0px 10px" }}>Requirements</h4>
      <p style={{ fontSize: "16px" }}>{requireText}</p>
      <Box className="requirements_prog_card_wrapper">
        {cardDetails.map((card, key) => (
          <Box key={key} className="requirements_prog_cards_card">
            <Box className="requirements_cards_title">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: !isPhone ? "18px" : "20px",
                  lineHeight: "40px",
                  color: "#2E2E2E",
                  margin: !isPhone ? "0px 0px 0px" : "0px 0px 90px",
                }}
              >
                {ReplaceSepcialChars(card.title)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: isPhone ? "0px 53px" : "0px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "15px 10px",
                  background: "#F8F8F8",
                }}
                className="requirements_card_years"
              >
                {card.years !== "" && (
                  <>
                    <p>Minimum: </p>
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "24px",
                        lineHeight: "30px",
                        textAlign: "center",
                        color: "#00C853",
                        margin: "0px 10px",
                      }}
                    >
                      {card.years}
                    </p>{" "}
                    <p>years</p>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export const paginationCurrentPage = (total, rowsPerPage = 10) => {
  const reminder = total % rowsPerPage;

  const pages = total - reminder;
  const pagesDiv = pages / rowsPerPage;

  return reminder > total ? pagesDiv + 2 : pagesDiv + 1;
};

export const PaginationRange = ({
  paginationPage,
  rowsPerPage,
  total,
  filteredTotal = [],
}) => {
  return (
    <p>
      {`Showing ${
        paginationPage === 1
          ? paginationPage
          : paginationPage * rowsPerPage - rowsPerPage
      } to ${
        paginationPage * rowsPerPage > total
          ? total
          : paginationPage * rowsPerPage
      } 0f ${filteredTotal.length === 0 ? total : filteredTotal.length}:`}
    </p>
  );
};
