import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IMG from "../../assets/images/prog_ade_img.svg";
import { getAdeContent } from "../../redux/programme/ProgrammeAction";
import FeeAde from "./ade/FeeAde";
import GeneralAde from "./ade/GeneralAde";
import RequirementAde from "./ade/RequirementAde";
import PaymentMethods from "./PaymentMethods";
import ProgSection from "./ProgSection";

function ADE() {
  const [section, setSection] = useState("general");
  const [sectionTitle, setSectionTitle] = useState("General info");
  const { loading, ade } = useSelector((state) => state.programme);

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdeContent());
  }, [dispatch]);
  return (
    <div style={{ padding: "1rem", width: "100%" }}>
      <ProgSection
        heading="Advanced Diploma in Education"
        image={IMG}
        setShow={setShow}
        setSection={setSection}
        section={section}
        setSectionTitle={setSectionTitle}
        show={show}
        sectionTitle={sectionTitle}
      />
      <Grid item sx={{ width: "100%" }}>
        {section === "general" ? (
          <GeneralAde content={ade} loading={loading} />
        ) : section === "requirements" ? (
          <RequirementAde content={ade} loading={loading} />
        ) : section === "fees" ? (
          <FeeAde content={ade} loading={loading} />
        ) : section === "payment" ? (
          <PaymentMethods content={ade} loading={loading} />
        ) : (
          <GeneralAde content={ade} loading={loading} />
        )}
      </Grid>
    </div>
  );
}

export default ADE;
