import { Grid } from "@mui/material";
import React, { useState } from "react";
import FeeCpd from "./cpd/FeeCpd";
import GeneralCpd from "./cpd/GeneralCpd";
import RequirementCpd from "./cpd/RequirementCpd";
import PaymentMethods from "./PaymentMethods";
import ProgSection from "./ProgSection";
import IMG from "../../assets/images/prog_cpd_img.svg";

function CPD() {
  const [section, setSection] = useState("general");
  const [sectionTitle, setSectionTitle] = useState("General info");

  const [show, setShow] = useState(false);
  return (
    <div style={{ padding: "1rem", width: "100%", marginBottom: "200px" }}>
      <ProgSection
        heading="Continuing Professional Development"
        image={IMG}
        setShow={setShow}
        setSection={setSection}
        section={section}
        setSectionTitle={setSectionTitle}
        show={show}
        sectionTitle={sectionTitle}
      />
      <Grid item sx={{ width: "100%" }}>
        {section === "general" ? (
          <GeneralCpd />
        ) : section === "requirements" ? (
          <RequirementCpd />
        ) : section === "fees" ? (
          <FeeCpd />
        ) : section === "payment" ? (
          <PaymentMethods />
        ) : (
          <GeneralCpd />
        )}
      </Grid>
    </div>
  );
}

export default CPD;
