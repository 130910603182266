export const IS_LOADING = "IS_LOADING";
export const GET_STUCTURE_PAGE_CONTENT = "GET_STUCTURE_PAGE_CONTENT";
export const GET_FUNCTION_PAGE_CONTENT = "GET_FUNCTION_PAGE_CONTENT";
export const GET_GOVERNANCE_PAGE_CONTENT = "GET_GOVERNANCE_PAGE_CONTENT";
export const GET_ACHIEVEMNT_PAGE_CONTENT = "GET_ACHIEVEMNT_PAGE_CONTENT";
export const GET_CENTERS_PAGE_CONTENT = "GET_CENTERS_PAGE_CONTENT";
export const GET_MANAGEMENT_PAGE_CONTENT = "GET_MANAGEMENT_PAGE_CONTENT";
export const GET_ZONAL_OFFICES = "GET_ZONAL_OFFICES";
export const GET_NORTH_EAST_PAGE_CONTENT = "GET_NORTH_EAST_PAGE_CONTENT";
export const GET_NORTH_WEST_PAGE_CONTENT = "GET_NORTH_WEST_PAGE_CONTENT";
export const GET_SOUTH_EAST_PAGE_CONTENT = "GET_SOUTH_EAST_PAGE_CONTENT";
export const GET_SOUTH_SOUTH_PAGE_CONTENT = "GET_SOUTH_SOUTH_PAGE_CONTENT";
export const GET_SOUTH_WEST_PAGE_CONTENT = "GET_SOUTH_WEST_PAGE_CONTENT";

export const GET_ABOUT_US = "GET_ABOUT_US";
export const SLIDER_CONTENT = "SLIDER_CONTENT";
