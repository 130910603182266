import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMediaQuery } from "react-responsive";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";

import PageContainer from "../component/PageContainer";
import "../css/Programme.css";
import NtiServicom from "../component/servicom/NtiServicom";
import ServicomContact from "../component/servicom/ServicomContact";
import {
  getServicomContactContent,
  getServicomContent,
} from "../redux/servicom/servicomAction";

function Servicom() {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [mobileActive, setMobileActive] = useState(0);

  const { servicom, servicomContact, loading } = useSelector(
    (state) => state.servicom
  );

  console.log(servicom);
  console.log(servicomContact);

  // setting the active setion style
  const classNameFunc = ({ isActive }) =>
    isActive ? "listItems listItems-active" : "listItems";

  const history = useLocation();
  const dispatch = useDispatch();

  const leftLinks = [
    {
      title: "NTI Servicom",
      link: "/servicom/nti-servicom",
    },
    { title: "Contact", link: "/servicom/contact" },
  ];

  const getSection = () => {
    switch (mobileActive) {
      case 0:
        return <NtiServicom content={servicom} loading={loading} />;
      case 1:
        return <ServicomContact content={servicomContact}  loading={loading}/>;
      default:
        <></>;
    }
  };

  const changeMobileActive = (type) => {
    // type can be 0 or 1 where 0 means previous and 1 means forward
    setMobileActive(type === 0 ? mobileActive - 1 : mobileActive + 1);
  };
  const [active, setActive] = useState("nti-servicom");

  useEffect(() => {
    setActive(history?.pathname?.split("/")[2] || "nti-servicom");
    // if (history?.pathname?.split("/")[2]) {
    //   $("html, body").animate(
    //     {
    //       scrollTop: $("#document").offset().top,
    //     },
    //     1000
    //   );
    // }
  }, [history.pathname]);

  useEffect(() => {
    dispatch(getServicomContent());
    dispatch(getServicomContactContent());
  }, []);
  return (
    <PageContainer>
      <Helmet>
        <title>Servicom | National Teachers' Institute Kaduna</title>
      </Helmet>
      <div id="document">
        {isPhone ? (
          <>
            <div style={{padding:'1rem'}}>
              <Grid container>
                <Grid container>
                  <div className="mobile_arrow_wrapper">
                    <button
                      disabled={mobileActive <= 0}
                      onClick={() => changeMobileActive(0)}
                      className="mobile-arrow-btn"
                    >
                      <ArrowBackIcon
                        style={{
                          color: mobileActive <= 0 ? "#D0E3F2" : "#4F9D6B",
                        }}
                      />
                    </button>

                    <p
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#46C35F",
                      }}
                    >
                      {leftLinks[mobileActive]?.title}
                    </p>

                    <button
                      onClick={() => changeMobileActive(1)}
                      disabled={mobileActive >= leftLinks.length - 1}
                      className="mobile-arrow-btn"
                    >
                      <ArrowForwardIcon
                        style={{
                          color:
                            mobileActive >= leftLinks.length - 1
                              ? "#D0E3F2"
                              : "#4F9D6B",
                        }}
                      />
                    </button>
                  </div>
                 
                 
                </Grid>
              </Grid>
              {getSection()}
            </div>
          </>
        ) : (
          <>
            <Grid
              container
              sx={{ margin: "5% 0", padding: "0px 92px", display: "flex" }}
            >
              <Grid item style={{ marginTop: "1rem", flex: 1 }}>
                <div id="floating-menu">
                  {leftLinks.map(({ link, title }, key) => {
                    return (
                      <NavLink
                        className={classNameFunc}
                        to={link}
                        key={key}
                        onClick={() => {
                          setActive(
                            history?.pathname?.split("/")[2] || "nti-servicom"
                          );
                        }}
                      >
                        {title}
                      </NavLink>
                    );
                  })}
                </div>
              </Grid>

              <Grid item style={{ flex: 4 }} className="about-components">
                {active === "nti-servicom" ? (
                  <NtiServicom content={servicom} loading={loading} />
                ) : (
                  <ServicomContact
                    content={servicomContact}
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </PageContainer>
  );
}

export default Servicom;
