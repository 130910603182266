import { Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import HomeHeaderContent from "./HomeHeaderContent";
import Background1 from "../../assets/images/img1.png";
import Background2 from "../../assets/images/background_img2.png";

function HomeHeader({ setActive, setActiveLink, activeLink }) {
  const [screen, setScreen] = useState(1);
  const toggleScreen = () => {
    if (screen === 2) {
      setScreen(1);
      return;
    } else {
      setScreen((prev) => prev + 1);
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      const interval = setInterval(() => {
        toggleScreen();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
    setScreen(1);
  }, [screen, location.pathname, toggleScreen]);

  return (
    <header className={`screen nr${screen}`}>
      <div className="header_wrapper">
        <Navbar
          setActive={setActive}
          setActiveLink={setActiveLink}
          activeLink={activeLink}
        />
        <HomeHeaderContent setScreen={setScreen} screen={screen} />
      </div>
    </header>
  );
}

export default HomeHeader;

// const { sliderImage } = useSelector((state) => state.about);

// console.log(sliderImage);

// const dispatch = useDispatch();

// useEffect(() => {
//   dispatch(slider());
// }, []);

//     className={`screen`}
//     style={{
//       background: `url(${screen === 1 ? sliderImage?.image1 : sliderImage?.image2})`,
//     }}
