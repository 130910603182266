import { Box } from "@mui/material";
import React from "react";

const Accordion = ({ heading, content }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <details className="accordion">
        <summary style={{ fontWeight: "700", cursor: "pointer" }}>
          {heading}
        </summary>
        <div className="content" style={{ paddingLeft: "4rem" }}>
          {content}
        </div>
      </details>
    </Box>
  );
};

export default Accordion;
