import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import "../../css/Home.css";
import Arrow from "../../assets/icon/home_arrow_icon.svg";
import Img1 from "../../assets/images/prog_nti_prog_img.svg";
import { Link } from "react-router-dom";
import {
  ArrowDownward,
  ArrowDropDown,
  ArrowForward,
} from "@mui/icons-material";
import { height } from "@mui/system";

function NtiProgramme() {
  const cardDetails = [
    {
      title: "TC II DLS",
      text: "Duration: 1980 to 2006",
    },

    {
      title: "NCE DLS",
      text: "Duration: 1990 to date",
    },
    {
      title: "PTTP DLS",
      text: "2000 to 2003, and 2009 to date in partnership with states",
    },
    { title: "Advanced Diploma", text: "Duration: 2005 to date" },
    {
      title: "PGDE DLS",
      text: "Duration: 2005 to date",
    },
    { title: "Bachelors Degree", text: "Duration: 2015 to date" },
  ];

  const progCards = [
    {
      text: "NCE",
      text2: "Nigeria Certificate in Education",
      link: "/programmes/nce",
    },
    {
      text: "PGDE",
      text2: "Post Graduate Diploma in Education",
      link: "/programmes/pgde",
    },
    {
      text: "B.ED",
      text2: "Bachelors Degree in Education",
      link: "/programmes/bed",
    },
    {
      text: "ADE",
      text2: "Advance Diploma in Education",
      link: "/programmes/ade",
    },
    {
      text: "PTTP",
      text2: "Pivotal Teachers Training Programme",
      link: "/programmes/pttp",
    },
    {
      text: "ADEE",
      text2: "Advanced Diploma in Environmental Education",
      link: "/programmes/adee",
    },
  ];

  return (
    <div className="nti_programme_wrapper">
      <h4 style={{ fontSize: "20px" }}>NTI Academic and CPD Programmes</h4>
      {/* <img src={Img1} alt="students" className="about_section_images" /> */}
      <p>
        Since inception, the Institute has mounted various programmes aimed at
        accelerating teacher development at all levels particularly at the basic
        education sector. This is with a view to raising the quality of
        education in the country in particular and the quality of overall
        national development in general.
      </p>

      <p style={{ marginTop: "1.2rem" }}>
        The <b> Academic Programmes</b> of the Institute by ODL are as follows:
      </p>

      <Box className="nti_prog_card_wrapper">
        {cardDetails.map((card, key) => (
          <Box key={key} className="nti_prog_cards_card">
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "40px",
                color: "#2E2E2E",
              }}
            >
              {card.title}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "30px",
                color: "#2E2E2E",
              }}
            >
              {card.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <p>
        In 2015, new upgrading (academic) programmes were introduced. They
        include: Nigeria Certificate in Education (NCE) in Business Education
        and in Computer Science Education and the Degree Programmes (BA. Ed
        English Language, BA. Ed Social Studies, BA. Ed Primary Education, BSc.
        Ed Mathematics, BSc. Ed Integrated Science, BSc. Ed Physical and Health
        Education.) New courses proposed to be introduced in 2017 session are:
        Advanced Diploma in ICT Integration for Teachers and Advanced Diploma in
        Environmental Studies for Teachers.
      </p>

      <section style={{ marginTop: "30px" }}>
        <h4>Our Programmes</h4>
        <div className="nti_prog_card_wrapper">
          {progCards.map((card, key) => (
            <div key={key} className="home_program_section_card_wrapper">
              <div className="home_program_section_card">
                <h4>{card.text}</h4>
                <p>{card.text2}</p>
                <Link className="home_program_section_card_link" to={card.link}>
                  <p>Read More</p>
                  <ArrowForward className="arrow" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default NtiProgramme;
