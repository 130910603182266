import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import FeeAdee from "./adee/FeeAdee";
import GeneralAdee from "./adee/GeneralAdee";
import RequirementAdee from "./adee/RequirementAdee";
import ProgSection from "./ProgSection";
import IMG from "../../assets/images/prog_adee_img.svg";
import PaymentMethods from "./PaymentMethods";
import { getAdeeContent } from "../../redux/programme/ProgrammeAction";
import { useDispatch, useSelector } from "react-redux";

function ADEE() {
  const [section, setSection] = useState("general");
  const [sectionTitle, setSectionTitle] = useState("General info");

  const { loading, adee } = useSelector((state) => state.programme);

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdeeContent());
  }, [dispatch]);
  return (
    <div style={{ padding: "1rem", width: "100%", marginBottom: "200px" }}>
      <ProgSection
        heading="Advanced Diploma in Environmental Education"
        image={IMG}
        setShow={setShow}
        setSection={setSection}
        section={section}
        setSectionTitle={setSectionTitle}
        show={show}
        sectionTitle={sectionTitle}
      />
      <Grid item sx={{ width: "100%" }}>
        {section === "general" ? (
          <GeneralAdee content={adee} loading={loading} />
        ) : section === "requirements" ? (
          <RequirementAdee content={adee} loading={loading} />
        ) : section === "fees" ? (
          <FeeAdee content={adee} loading={loading} />
        ) : section === "payment" ? (
          <PaymentMethods content={adee} loading={loading} />
        ) : (
          <GeneralAdee content={adee} loading={loading} />
        )}
      </Grid>
    </div>
  );
}

export default ADEE;
