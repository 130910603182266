import { ArrowOutward, Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import PageContainer from "../component/PageContainer";
import { paginationCurrentPage, PaginationRange } from "../util/util";
import "../css/News.css";
import { useSelector, useDispatch } from "react-redux";
import { getNewsContent } from "../redux/news/NewsAndMediaAction";
import { Helmet } from "react-helmet-async";
// import { news } from "../util/tempNews";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ReplaceSepcialChars } from "../util/escapeChar";

function News() {
  const isPhone = useMediaQuery({ query: "(max-width: 900px)" });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = React.useState(0);
  const [searching, setSearching] = useState(false);
  const [paginationPage, setPaginationPage] = React.useState(1);
  const { news, isLoading } = useSelector((state) => state.news);

  const handleChangePage = (event, newPage) => {
    const page = newPage - 1;
    setPaginationPage(newPage);
    setPage(page);
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearching(true);
    try {
      const result = Array.from(new Array(6))?.filter((res) => {});
    } catch (error) {
      console.log(error);
    } finally {
      setSearching(false);
    }
  };

  useEffect(() => {
    dispatch(getNewsContent());
  }, [dispatch]);

  return (
    <PageContainer>
      <Helmet>
        <title>News & Media | National Teachers' Institute Kaduna</title>
      </Helmet>
      <Box
        sx={{
          padding: isPhone ? "100px 16px 200px" : "100px 92px 200px",
        }}
      >
        <Box sx={{ padding: "0px 80px" }}>
          <h4>News and Media</h4>
        </Box>
        <Box
          className={
            isPhone ? "news_filter_wrapper_phone" : "news_filter_wrapper"
          }
        >
          <Box
            sx={{ display: "flex", alignItems: "center", width: "50%" }}
            component="form"
          >
            <p>Sort by:</p>

            <select name="" id="" className="news_select_filter">
              <option value="">News</option>
            </select>
          </Box>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: isPhone ? "100%" : "30%",
              background: "#D7E2EC",
              opacity: 0.3,
              borderRadius: "10px",
            }}
            onSubmit={handleSearch}
          >
            {searching ? (
              <CircularProgress style={{ color: "#343434" }} size={20} />
            ) : (
              <IconButton
                onClick={handleSearch}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <Search sx={{ color: "#84939B" }} />
              </IconButton>
            )}

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search google maps" }}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </Paper>
        </Box>
        {isLoading ? (
          <Box className="news_skeleton_wrapper">
            {Array.from(new Array(6)).map((num, key) => (
              <Box key={key}>
                <Skeleton variant="rectangular" width={"100%"} height={218} />

                <Box sx={{ pt: 0.5 }}>
                  <Box className="news_skeleton_sub_heading_wrapper">
                    {" "}
                    <Skeleton /> <Skeleton />
                  </Box>
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="20%" />
                </Box>
              </Box>
            ))}
          </Box>
        ) : news?.length === 0 ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            sx={{ marginTop: ".5rem" }}
          >
            <p>No News available</p>
          </Stack>
        ) : (
          <Box
            className={
              isPhone
                ? "news_card_wrapper_phone news_card_wrapper"
                : "news_card_wrapper"
            }
          >
            {news
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((news, key) => {
                return (
                  <Box
                    key={key}
                    className="news-news-card-wrapper"
                    onClick={() => {
                      navigate(`/news/${news?.id}`);
                    }}
                  >
                    <img
                      src={`https://www.nti.edu.ng/static/media/Logo.b5d023f99a9bae47a2886b993915b4d9.svg`}
                      alt="news-img"
                      width={"60%"}
                      height={"320px"}
                    />
                    <Box className="home_news_cat" style={{marginTop: '-20px'}}>
                      <p>{news?.category}</p>
                      <p>
                        {news?.created_at &&
                          moment(news?.created_at).format("Do MMMM  YYYY")}
                      </p>
                    </Box>
                    <h4 className="home_section_h4">
                      {`${ReplaceSepcialChars(news?.title.substring(0, 50))}...`}
                    </h4>
                    <p
                      className="home_section_news_p"
                      dangerouslySetInnerHTML={{
                        __html: news?.paragraph.substring(0, 100) + "..." || "",
                      }}
                    />
                    <Box
                      className=""
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p>Read more</p>
                      <ArrowOutward
                        sx={{
                          color: "#4F9D6B",
                        }}
                      />
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
        <Box
          className="table_pagination"
          sx={{
            flexDirection: isPhone && "column",
            alignItems: isPhone && "center",
            gap: isPhone && "40px 0px",
          }}
        >
          <PaginationRange
            paginationPage={paginationPage}
            rowsPerPage={rowsPerPage}
            total={news?.length}
          />
          <Pagination
            onChange={handleChangePage}
            page={paginationPage}
            count={paginationCurrentPage(news?.length)}
            shape="rounded"
          />
        </Box>
      </Box>
    </PageContainer>
  );
}

export default News;
