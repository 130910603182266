import React from "react";
import Pics from "../../assets/images/about_nti_centers.png";
import { useSelector } from "react-redux";

function Centers() {
  // const { centers } = useSelector((state) => state.about);
  return (
    // <section
    //   className="about_centers"
    //   dangerouslySetInnerHTML={{
    //     __html:
    //       centers?.content?.rendered
    //         .replace("[vc_row][vc_column][vc_column_text]", " ")
    //         .replace("[/vc_column_text][/vc_column][/vc_row]", " ") || "",
    //   }}
    // />
    <div className="about_centers">
      <h4>The NTI Specialised Centres</h4>
      {/* <img src={Pics} alt="" className="about_section_images" /> */}
      <p style={{ marginBottom: "0px" }}>
        The Institute also has Specialized Centres and they include:
      </p>
      <ol>
        <li>
          Centre for Strengthening Mathematics and Science Education (SMASE),
        </li>
        <li>Centre for E-Learning.</li>
      </ol>

      <p>
        The Centre for the Strengthening Mathematics and Science Education
        (SMASE) has trained 438 teachers in Science and Mathematics nationwide.
        This training has been cascaded in each state to cater for more
        teachers. Similarly, the Centre for Entrepreneurship and Special
        Education has CPD programmes that will help develop and upgrade the
        skills and knowledge of teachers at basic level.
      </p>
    </div>
  );
}

export default Centers;
