import * as NewsTypes from "./NewsAndMediaTypes";
import { httpRequest } from "../../https/https";
import { API_ROUTES } from "../../constant/config";

export const setIsLoading = (value) => ({
  type: NewsTypes.IS_NEWS_LOADING,
  payload: value,
});

export const getNewsContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES.get_news.route,
      method: API_ROUTES.get_news.method,
    });
    dispatch({
      type: NewsTypes?.GET_NEWS,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};
