import DirectorGeneral from "../assets/images/Director-General.jpg";
import AgRegistrar from "../assets/images/ag-registrar-picture.png";
import SMASEOverseer from "../assets/images/SMASE-Overseer.png";
import DirectorAcademics from "../assets/images/Director-Academics.jpg";
import DirectorFOOS from "../assets/images/Director-Foos.png";
import DirectorExamination from "../assets/images/Director-Examination.jpg";
import SpecialAssistant from "../assets/images/Special-Assistant-DG.jpg";
import DirectorQA from "../assets/images/Director-Quuality-Assurance.jpg";
import Bursar from "../assets/images/bursar.jpg";

export const bioDetails = [
  {
    title: "Director General and Chief Executive",

    picture: DirectorGeneral,
    name: "Prof. Musa Garba Maitafsiri",
    briefHistory: `Kaduna. Prof. Musa Garba Maitafsir was born on Thursday, 3rd September, 1959. He attended the then Yabo Town Primary School in Sokoto State between 1972 and 1976. He is a product of Government Teachers’ College, Wurno in Sokoto State between 1976 and 1981. He obtained his B.A. Ed. Degree from University of Sokoto in 1987, M.Ed. (Educational Psychology) from University of Lagos in 1990 and Ph.D. (Educational Psychology) from the famous University of Science, Malaysia (USM), 1999.He was at Bengaje Primary School as a teacher for one year (1982). After graduation from the university, he was employed by the Muslim Community School, Idi-Araba, Mushin, Lagos, where he served as the Principal in 1988.
      ${(<br />)}
      He was later employed into the Federal Ministry of Education, posted to Federal Government Girls College, Bida, Niger State as a teacher from 1988 to 1989. In 1989, the Usmanu Danfodiyo University, Sokoto appointed him as Graduate Assistant, later promoted to Assistant Lecturer in 1989, Lecturer II in 1991 and in 1993 to Lecturer I. He became a Senior Lecturer in 1996 and finally a Professor of Educational Psychology in 2003. He has supervised many M. Ed, and Ph.D students. He was the Head of Educational Foundations Unit in the Faculty of Education and Extension Services, Usmanu Danfodiyo University, Sokoto for two terms of four years. He was also the Deputy Dean of Students’ Affairs, Usmanu Danfodiyo University, Sokoto for two and half terms of three years. He served as the Science Unit Co-ordinator of the B. Ed. Degree programme.
      ${(<br />)}
     The new NTI’s Director-General and Chief Executive was also the Chairman of Yabo Local Government in 2007 and was the first Commissioner for Religious Affairs, Sokoto State from 2008 to March, 2014. He was the Executive Chairman, State Universal Basic Education Board Sokoto State (SUBEB). As an academic, he has published many articles in both national and international journals in Brunei Darassalam, Singapore, China, Australia, UK and Malaysia. He is a member of many National and International Academic Associations; such as Educational Research Association (ERA) Singapore, Malaysian Educational Research Association (MERA) Malaysia and Transpersonal Psychotherapy On-line Association, Australia and Nigerian Association of Educationists for National Development (NAEND), among others. It is interesting to note that he is the brain behind the establishment of the best Almajiri Integrated School in Nigeria. The school that served as the model for the Federal Government to establish other Almajiri Schools.
      ${(<br />)}
      He is the proprietor of one of the best and the cheapest Nursery, Primary and Secondary Schools in Sokoto State, Caliphate Tarbiyya Academy and also the proprietor of the first Private College of Education in the North Western part of Nigeria, Biga College of Education, Sokoto, the college that gives thousands of scholarships to the less privileged students of Sokoto, Kebbi, Zamfara and other states.Prof. Maitafsir is a consultant to EDOREN (Education Data, Research and Evaluation in Nigeria) and a Member EDOREN Advisory Committee in Nigeria. He taught at both undergraduate and post-graduate levels and supervised post- graduate theses as a Major or co-supervisor. He had presented papers at national and international seminars. Author and co-author many publications in reputable journals such as “Teachers’ Guide to Behaviour Modification in Schools: An Islamic analysis, published in Al-Bayan Journal of Islamic Research. Vol. 1 No. 1. pp 98, among others. He was appointed as the Director-General and Chief Executive of NTI, Kaduna, effective from September 2, 2021 by His Excellency, President Muhammadu Buhari, GCFR`,
  },

  {
    title: "Ag. Registrar & Secretary to Council",

    picture: AgRegistrar,

    briefHistory: `The NTI Governing Council has with effect from April 10, approved the appointment of Mal. Ibrahim Hamidu as Acting Registrar and Secretary to the Council. ${(
      <br />
    )} Approval for the appointment was disclosed in a Memorandum dated April 15, addressed to all staff of the Institute from the Council Secretariat and signed by Mal. Yusuf Aliyu, Deputy Registrar II at the Institute’s Registry.
      ${(<br />)}
      
      “I write to convey the approval of the Governing Council the appointment of Mal. Ibrahim Hamidu as the Ag. Registrar and Secretary to the Governing Council with effect from 10th April, 2021,” it said.
      ${(<br />)}
The Memorandum indicated that Hamidu’s appointment was part of the resolutions taken at the Council’s meeting held on Thursday, April 8, 2021.
${(<br />)}
Until his appointment, he was the Deputy Registrar 1 (Works, Housing and Transport) of the Institute.
${(<br />)}
Mal. Hamidu joined the services of the Institute in 2009 as an Assistant Director Administration, Senior Special Assistant (SSA) to the then DG/CEO, Dr. Aminu Ladan Sharehu, (Mutawallin Zazzau).
${(<br />)}
While in the service of the Institute, he served in various capacities such as Assistant-Director Administration, under Program Design and Development (PD&D) Division, Department of Academic Services; Assistant Director Administration, Field Operations and Student Services (FOSS) and NTI Center Desk Officer (CDO) for NCE Study Centre at Government Technical College Pategi, Kwara  State.
${(<br />)}
He was also a CDO for NCE Study Centre, Government College Katsina and Pioneer NTI Center Desk Officer for Bachelors’ Degree Programme (BDP) at the Federal College of Education (FCE) Katsina.
${(<br />)}
He held the position of Deputy Registrar 1 (NTI Ventures).
${(<br />)}
The new Acting Registrar was born on 21st June, 1964 in Dogarawa, Sabon Gari Local Government Area of Kaduna State and obtained his Primary School Certificate in 1978 at LEA Sabon Gari , Zaria.
${(<br />)}
He attended Rimi College, Kaduna and upon completion in 1983 with flying colours, he
${(<br />)}
proceeded to the College of Advanced Studies (CAS) for IJMB programme which, he completed in 1985.
${(<br />)}
Mal. Hamidu then proceeded to the famous Ahmadu Bello University (ABU) Zaria, where he obtained his B.Sc  (Hons)  Economics in 1988.
${(<br />)}
He later had a Post-graduate Diploma in Management (PGDM) and Masters of Business Administration (MBA) in 1999 and 2004 respectively from the same university.
${(<br />)}
The new Acting Registrar also obtained Post Graduate Diploma in Education (PGDE) at the Federal College of Education (FCE), Zaria in 2013.
${(<br />)}
Our man of the moment is currently undergoing M.Ed. Social Studies, which is at External Defence Level, Faculty of Education, ABU, Zaria.
${(<br />)}
The new Acting Registrar, started his working career with the Nigerian Economist Magazine as a Reporter/Researcher on Economic/Financial Desk at Oregun, Ikeja, Lagos in 1988 and later joined the National Economic Reconstruction Fund (NERFUND), a Parastatal under the Federal Ministry of Finance, financing Small and Medium Enterprises; in Lagos and later Abuja between 1991 and 1999.
${(<br />)}
He has worked with numerous commercial banks, such as Access Bank Plc, Cooperative Development Bank (CDB) Plc, Diamond Bank Plc, and First City Monument Bank (FCMB) Plc, in various capacities.
${(<br />)}
By professional callings, he is a Fellow of Chartered Institute of Human Capital Development (FCIHCDN); Nigerian Institute of Management (Chartered) NIM and the Institute of Cost Management of Nigeria (ACM).
${(<br />)}
Other distinguished Associations the Acting Registrar belongs include being: an Associate Member of Nigeria Association of Chartered Institute of Bankers (NACIB) and Institute of Industrialists and Corporate Administrators (MIICA).
${(<br />)}
He held positions in various Committees and attended conferences, workshops/seminars both locally and internationally.
${(<br />)}
The new Acting Registrar has a record of distinguished awards  of merit, this includes a Fellowship Award by the Chartered Institute of Human Capital Development (FCIHCDN), Abuja, Best Performing Branch Manager , FCMB Plc and Manager of the Year(2008) North-West(Consumer) FCMB, Plc.
${(<br />)}
Others are Award for Excellence, AMELSTON ABUTH, Zaria and Award for Excellence by ‘’Amintacciyar Zumunta’’ Association (AZA)-Zaria, among others.
${(<br />)}
Being an active member of his own community, Mal. Hamidu served as Chairman, Police Community Relations Committee (PCRC), Sabon-Gari Local Government Police Division, between 2015 and 2019; Public Relations Officer, Police Community Relations Committee (PCRC) for Kaduna State Police Command between 2005 and 2015.
${(<br />)}
Mal. Ibrahim Hamidu is happily married with Children. The Bulletin wishes him a successful tenure in office.
${(<br />)}${(<br />)}${(<br />)}
 

Source: NTI News Bulletin Bi-monthly edition March-April 2021
      
      `,
  },
  {
    title:
      "Overseer, Strengthening Mathematics and Science Education (SMASE)  National INSET Centre, NTI Headquarters, Kaduna",
    picture: SMASEOverseer,
    name: "Dr. Zainab Muhammad Shuaibu",
    briefHistory: `

Dr. Zainab Muhammad Shuaibu is the Overseer of the National Centre for the Strengthening Mathematics and Science Education (SMASE), a National INSET Centre at the National Teachers’ Institute, Kaduna.
${(<br />)}
She was born on May 25, 1972 in Zaria, Kaduna State, which is her state of origin. Dr. Zainab holds a Doctorate Degree (Ph.D.) in Science Education from Abubakar Tafawa Balewa University, Bauchi (ATBU), which she obtained between 2011 and 2017, M.Ed. Science Education from the famous Ahmadu Bello University, Zaria (ABU) between 2006 and 2009 and a Bachelor Degree in Integrated Science from University of Jos, between 1998 and 2001.
${(<br />)}
Beside being a holder of a Nigeria Certificate in Education (Physics and Chemistry), she also holds a National Diploma Certificate in Data Processing.  Earlier to these credentials, she had undergone her Primary and Post-Primary education successfully. 
${(<br />)}
As a teacher, she taught Basic Science and Technology at the primary and junior secondary schools as well as Chemistry at the Senior Secondary school. She was later appointed NTI Course tutor, tutoring Integrated Science for NCE students through the Open and Distance Education (ODE).
${(<br />)}
Dr. Zainab climbed the ladder progressively to become a Subject Officer Examinations, coordinating Integrated Science for NTI/NCE through the ODE Examination and later a Desk Officer Examinations for the Special Teacher Upgrading Programme (STUP) under the NTI, Kaduna. Until her appointment as the SMASE National INSET Centre Overseer, Dr. Zainab was the Head of Trainings and National Trainer at the Centre.
${(<br />)}
Presently, she is not only the Contact Person on “Reskilling Teacher Educators and Teachers in OER Development for Learning Recovery in Kebbi and Sokoto States, Nigeria,’’ (a collaborative project between the National Teachers’ Institute, Kaduna Nigeria and the Commonwealth of Learning, Vancouver, Canada); but also, the  Secretary, Green Teacher Nigeria (GTN) Core Team, (a National Teachers’ Institute (NTI) and Commonwealth of Learning (COL) Environmental Education programme)  and On-line Facilitator with the Green Teacher Nigeria (GTN) programme on the National Teachers’ Institute Moodle site.
${(<br />)}
Dr. Zainab has other Certificates in chosen fields of interest and specialisation, with membership of professional bodies cutting across professional associations. She has attended conferences, seminars and workshops both locally and internationally. All these must have prepared her for the task ahead. She is married with children.

`,
  },

  {
    title: "Director, Academic Services Department",
    picture: DirectorAcademics,
    name: "Dr. Hafsat Lawal Kontagora ",
    briefHistory: `
    
    Dr. Hafsat Lawal Kontagora was born on 21st March 1968, in Zaria Local Government of Kaduna State. She attended Women Teachers’ College, Kabomo Katsina State from 1980-1985 and obtained Teachers’ Grade Two certificate, Advanced Teachers’ College/A.B.U, Zaria from 1985-1988 and obtained Nigerian Certificate in Education(N.C.E), Ahmadu Bello University,  Zaria from 1994-1996 and obtained Bachelor Degree in Education (Social Studies), Bayero University Kano from 2002-2008 and obtained Masters’ Degree in Education (Educational Psychology), University of Abuja from 2008-2012 and obtained PhD (Educational Psychology).
    ${(<br />)}
She possess additional qualifications among which are: Advanced Research Training Workshop, Damina School Centre for Research and Documentation, Kano (2004), Advanced Certificate in Arabic (2005), Computer Literacy Course (2006), Training Workshop for H.O.Ds, Exam Officers, and Registration Officers of Kaduna Polytechnic (2008), Research Capacity Building Training Workshop ETF/Kaduna Polytechnic (2011), Advance Digital Appreciation Programme for Tertiary Institutions (2013), Capacity Development Workshop for Pre-Service Teachers on Culturally Relevant Early Childhood Care and Education (2014).
${(<br />)}
She served at various capacities before and after joining the National Teachers Institute few among them are: Director School of Teacher Professional Development, Research & Examination Examination, Research & Academic services, Senior Lecturer/Exam Officer Lecturing; Students’ Project supervision; Research; Conducting exams; Result collation and compilation; Timetable preparation; Educational and Psychology consultancy services Kaduna Polytechnic etc.
${(<br />)}
She also has several research works that include: (1) Nigeria Stability and Reconciliation Programme (NRSP) Women and Girls’ Peace club Cohort Study, Kaduna State 2016, (2) EDOREN Study on “identifying, recruiting and deploying effective teachers” Phase 2 2015, (3) GEP3 External Evaluation Study for Education & Operational Research and Evaluation in Nigeria. (EDOREN) 2014, (4) Nigeria Stability and Reconciliation Programme (NRSP) Conflict Mapping of Land and Water Resources national Study (Kaduna state) for British Council and Centre for Peace Studies University of Jos 2013, (5) The Perceived Effect of Learning Environment on the Retention of Nomadic Fulani Pupils of Kaduna State (PHD Thesis, University of Abuja) 2012, and so many more write ups.
${(<br />)}
She has several publications that include: The Nomadic Girl Child and Education (2002): The Academic Standardizer-Journal, Stimulating Female Participation in Technological Education (2006): Journal of Women in Technical Education (JoWITED), Social Studies Pupils’ Text and Teachers’ Guide for Nomadic Primary Schools: books 1, 2 and 3 (2007), Psychology for Beginners (2008) A book, The positive and negative effect of globalization on women and their Development (2009) Business and Management Forum Journal- CBMS- Kaduna Polytechnic etc.
${(<br />)}
Member Association of Nigerian Educators (ANE), Nigeria Association of Educational Psychologists (NAEP), Nigeria Psychological Association (NPA), Social Studies Association of Nigeria (SOSAN), Nigerian Association of Social Workers Educators(NASWE), Nigerian Association of Social Workers (NASoW), Women in Technical Education and employment (WITED), International Psychologists, International Association of Applied Psychology (IAPP), International Association of Muslim Psychology (IAoMPSY).
${(<br />)}
She also served the Institute as Director, Centre for Strengthening Mathematics and Science Education (SMASE), before her new posting as Director of Academic Services Department.
    `,
  },
  // { title: "Specialized Centers", picture: Pics },
  {
    title: "Director, Field Operations & Students Services (FOSS) Department",
    picture: DirectorFOOS,
    name: "Dr. Chizoba Francisca Umerah",
    briefHistory: `Dr. Chizoba Francisca Umerah was born on August 11, 1964, to the family of late Ononenyi John, A. Ndulue and late Mrs. Flora Ageh Ndulue(Nee) Onyido of Ogbendida Village , Onitsha , in Onitsha North Local Government Area of Anambra State.
      ${(<br />)}
      Umerah who is a seasoned educationist and administrator par excellence attended St. Monica’s Teachers’ Training College, Ogbunike, where she obtained her  (TC II) in 1981 and Nwafor Orizu College of Education Nsugbe for NCE in Agricultural Science in 1985.
      ${(<br />)}
      She proceeded to the famous  University of Nigeria, Nsukka and obtained a B.Ed in Guidance and Counseling in the year 1989 and did the One year NYSC at the FCT NYSC Secretariat, in 1991.
      ${(<br />)}
      She obtained M.Ed in Educational Management from the University of Benin in 1995 and Ph.D in Educational Administration and Management, Enugu State University of Science and Technology in 2012
      ${(<br />)}
      She joined the services of NTI, Kaduna, on September 1, 1994 and served at various capacities. She was a Senior and Principal Education Officer respectively in Enugu State Office from 1994 to 2000.
      ${(<br />)}
      Umerah who was the pioneer State Coordinator in Anambra State Office from 2000 to 2011 and the first female Zonal-Coordinator in the South-East Zone from 2011 to 2018 is a registered teacher with the Teachers Registration Council of Nigeria(TRCN). She is a fellow with the Institute of Corporate Administration (FCAI) in Organisational Administration and Chartered member of Nigeria Institute of Management (MNIM).Besides being an Examination Ethics Friend (A Committee she served in Anambra State Ministry of Education for many years).
      ${(<br />)}
      In April, 2018, she was deployed to Benin City, Edo State as the South-South Zonal Coordinator and later as a Deputy-Director in the Department of Academic Services.
      ${(<br />)}
      She was also the Director of Academic Services Department before her posting to FOSS Department as the new Director.
      ${(<br />)}
      The Director has edited and published many books as well as attended several seminars and conferences in addition to having many awards to her credit in addition to being  happily married with children.
      
      `,
  },
  {
    title: "Director, Examinations Department",
    picture: DirectorExamination,
    name: "Alh. Bashir Mamman",
    briefHistory: `Alh Bashir Mamman was born on the 1st April 1965 in Daura Local Government of Katsina state. He attended Daura I Primary School Daura, Barewa College Zaria, ATC-ABU Zaria, Ahmadu Bello University Zaria, Newcastle University UK and Bayero University Kano. His highest qualification is a Master’s in Education (Test and Measurement) and a P. hD in view (Test and Measurement).
${(<br />)}
He has served in the National Teachers’ Institute as a teacher, Subject officer ITS, coordinator of all programmes at examinations department, Katsina state coordinator, SA to DG & CE, Dean School of Education and currently the Dean School of undergraduate studies.

`,
  },
  {
    title: "Bursar",
    picture: Bursar,
    name: "Malam Yunusa Labaran, Bursar",
    briefHistory: `The NTI Governing Council has approved the confirmation of Mal. Yunusa Danbala Labaran, as the Institute’s substantive Bursar with effect from January 1, 2021.
    ${(<br />)}
    Labaran’s confirmation was made known through a Memorandum to all NTI staff, issued on Thursday, April 15, by the Institute’s Council Secretariat and signed by Mal. Yusuf Aliyu, an Assistant-Deputy Registrar at the Institute’s Registry.
    ${(<br />)}
    The Memorandum indicated Labaran’s substantive appointment was part of the resolutions passed by the Council at its meeting held on Thursday, April 8, 2021.
    ${(<br />)}
    Labaran, the hitherto Acting Bursar of the Institute was born on January 11, 1966 and hailed from Sumaila Local Government Area of Kano State.
    ${(<br />)}
    He attended L.E.A Primary School, Gwari Road, Kaduna between 1973 and 1979 and from 1979 to 1984, he was at Government Secondary School, Kakuri, Kaduna, for his secondary education.
    ${(<br />)}
    Mal. Labaran obtained his National Diploma in Accounting and Finance and Higher National Diploma in Accounting and Finance from the famous Kaduna Polytechnic between 1989 and 1995, respectively.
    ${(<br />)}
    In 1998, he obtained a Post-Graduate Diploma in Financial Management from Abubakar Tafawa Balewa University, Bauchi and another Post-Graduate Diploma in Management from the prestigious Bayero University, Kano in 2002.
    ${(<br />)}
    Our Chancellor of the Exchequer is a Certified National Accountant (CNA), certified by the Nigeria College of Accountancy, Jos.
    ${(<br />)}
    He joined the services of the Institute in November 20, 1990 and served variously as Accountant in Imo, Ekiti, Kogi and Kaduna State Offices.
    ${(<br />)}
     He got elevated to the position of Principal Auditor in 2006 and subsequently became the Assistant Chief Internal Auditor.
     ${(<br />)}
    He later rose to become the Institute’s Chief Internal Auditor and was at different times, the Assistant Director and Deputy Director, Audit respectively.
    ${(<br />)}
    The new Bursar, is a Fellow, Association of National Accountants of Nigeria (ANAN), Fellow, Chartered Institute of Taxation of Nigeria (CITN) and Fellow, Institute of Chartered Executives of Nigeria (ICEN).
    ${(<br />)}
    He has attended several conferences, seminars/workshops both locally and internationally.
    ${(<br />)}
    Our man of the moment, who is married with children likes reading and travelling.
    ${(<br />)}
    The Bulletin wishes Labaran success in the discharge of his duties.
    ${(<br />)}
    ${(<br />)}
    ${(<br />)}
    Source: NTI News Bulletin, March-April Bi-monthly edition

    `,
  },
  {
    title: "Special Assistant to the DG",
    picture: SpecialAssistant,
    name: "Dr. Armiya’u Malami Yabo",
    briefHistory: `Dr. Armiya’u Malami  Yabo has with effect from January 13, 2021, been appointed as the Special Assistant (SA) to Prof. Garba Dahuwa Azare, the NTI Director-General  and Chief Executive(DG/CE).
${(<br />)}
Born on January 17, 1978, the new SA to DG/CE holds a Ph.D in Linguistics, hailed from Yabo town of Yabo Local Government Area of Sokoto State, North-West, Nigeria.
${(<br />)}
He completed his Primary School education in the year 1988 and Secondary School education in 1994; from there he proceeded to Shehu Shagari College of Education, Sokoto, where he obtained Nigeria Certificate in Education (NCE) in 1999.
${(<br />)}
He then proceeded to Usmanu Danfodiyo University, Sokoto, where he bagged a Bachelor of Arts (B.A) Degree in English Language from the Department of Modern European Languages and Linguistics in 2003 and later Master of Arts (M.A) Degree in 2010 and capped it with a Ph.D in Linguistics  in the year 2018.
${(<br />)}
Dr. Yabo, who is versatile, has been a professional and certified teacher, registered by the Teachers’ Registration Council of Nigeria (TRCN).
${(<br />)}
He is also a registered member of English Scholars’ Association of Nigeria (ESAN) and English Language Teachers’ Association of Nigeria (ELTAN).
${(<br />)}
He has written and published numerous articles on Teacher quality and qualifications in Nigeria, which cover areas of Phonology of Nigerian English and Issues bordering on Open and Distance Education in the Global South.
${(<br />)}
It is worthy of note to report that Dr Yabo had alongside Prof. Ofulue Christiane a Lead Researcher, from the National Open University of Nigeria and three other colleagues, recently won a TetFund 2020 NRH Research grant of ₦27,971,240.00 for a research titled: “Setting an Agenda for Research into Open, Distance and E-learning in the Global South.’’
${(<br />)}
It is also interesting to note that his Ph.D thesis has also been awarded and recognised for Publication by the TetFund , being the best-written Ph.D thesis of the year 2018 from the Faculty of Arts and Islamic studies, Usmanu Danfodiyo University, Sokoto.
${(<br />)}
Having considered the capacities in which he served the Institute as an adhoc-staff for over nine years, the Governing Council and the Management of the Institute approved his appointment and re-deployment as State Coordinator, Sokoto State office on May 12, 2012, where he served up to June 29, 2020.
${(<br />)}
Prior to his appointment into the Institute, he served in adhoc capacities as English Language Course Tutor for Grd II Students; Pivotal Teacher Training Programme (PTTP); Special Teacher Upgrading Programme (STUP) as well as the Nigeria Certificate in Education by Distance Learning system (NCE/DLS).
${(<br />)}
 Others areas he served meritoriously included Oral English Assistant Examiner up to the rank of a Centre Manager as well as Member Advisory Committee of the Sokoto State Office of the National Teachers’ Institute, representing the Sokoto State wing of the Nigeria Union of Teachers.
 ${(<br />)}
For the purpose of administrative convenience, he was redeployed to the Department of Academic services on June 29, 2020, and posted to Planning, Research and Development (PRS) Division as well as Coordinator E-learning for a period of six months.
${(<br />)}
${(<br />)}
${(<br />)}
Source: NTI News Bulletin, January-February 2021 Bi-monthly Edition.

`,
  },
  {
    title: "Director, Quality Assurance & ICT Department",
    picture: DirectorQA,
    name: "HAJIYA FATIMA AHMAD BALGORE",
    briefHistory: `
    Hajiya Fatima Ahmad Belgore, the Director Quality Assurance and ICT Department, was born on March 21, 1963 and hailed from Ilorin South Local Government Area of Kwara State.
    ${(<br />)}
She obtained a Master’s Degree in Educational Management from the University of Ilorin in 2005 and PGDE from the same University in 1995.
${(<br />)}
The Director had earlier in 1989 graduated with a B.A. (Hons) Islamic Studies from the prestigious Bayero University, Kano, having earlier enrolled in the University’s Six –Term Pre-Degree in Arabic, Hausa and Islamic Studies.
${(<br />)}
Belgore attended Women Arabic Teachers’ College, Goron Dutse, in Kano State, where she obtained her Teachers’ Grade II Certificate in 1984 and GCE from Government Secondary School, Kaiama, in Kwara State.
${(<br />)}
She did her mandatory NYSC assignment at Aminu Kano Commercial College in Kano between 1989 and 1990 and thereafter secured a full-time appointment with the Kwara State Teaching Service Commission from 1990-1997 and served as a part time Lecturer at the Kwara State College of Arabic, Islamic and Legal Studies, Ilorin, from 1995 to 1997.
${(<br />)}
Belgore, who got employed by the Institute in 1997 served in various capacities which included being the Education Officer in Kwara and Kogi States between 1997 and 1998; State Coordinator in the same states from 1998 to 2013.
${(<br />)}
She was at the helm of affairs at NTI FCT from 2013 to 2014 where she got elevated to the position of Zonal Coordinator for NTI North Central Zone from 2014 to 2019.
${(<br />)}
 She served as the head, Standard Division of the NTI Quality Assurance and ICT from March –December 7, 2019, before her appointment as Ag. Director Quality Assurance and ICT Department on December 8, 2019.
 ${(<br />)}
The Director who has several academic journal publications to her credit, also attended several international and local workshops and seminars in addition to being a member of many humanitarian, religious and professional bodies as well as having received a couple of merit awards.
${(<br />)}
Belgore who is married with children, enjoys reading, travelling and making friends.
${(<br />)}
Belgore has however, been confirmed as a substantive Director with effect from January 1, 2020.
    
    `,
  },
];
