import * as servicomTypes from "./servicomTypes";
import { httpRequest } from "../../https/https";
import { API_ROUTES } from "../../constant/config";

export const setIsLoading = (value) => ({
  type: servicomTypes?.IS_LOADING,
  payload: value,
});

export const getServicomContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES?.get_servicom_content?.route,
      method: API_ROUTES?.get_servicom_content?.method,
    });
    dispatch({
      type: servicomTypes?.SERVICOM,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getServicomContactContent = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await httpRequest({
      url: API_ROUTES?.get_servicom_contact_content?.route,
      method: API_ROUTES?.get_servicom_contact_content?.method,
    });
    dispatch({
      type: servicomTypes?.SERVICOM_CONTACT,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};
