import { Box, Typography } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { RequirementsCard } from "../../../util/util";
import BottomPGDECard from "./BottomPGDECard";

function Requirements({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const cardDetails = [
    {
      title: content?.first_requirement,
      years: content?.first_requirement_minimum,
    },

    {
      title: content?.second_requirement,
      years: content?.second_requirement_minimum,
    },
    {
      title: content?.other_requirement,
      years: content?.second_requirement_minimum,
    },
  ];
  return (
    <div style={{ marginBottom: "200px" }}>
      <RequirementsCard
        cardDetails={cardDetails}
        requireText="Candidates who possess any of the under listed qualifications are eligible for admission:"
        isPhone={isPhone}
      />
      <BottomPGDECard
        fee={content?.application_fee_amount}
        isPhone={isPhone}
        semester={content?.course_semester_duration}
        year={content?.course_semester_years}
        note={content?.note}
      />
    </div>
  );
}

export default Requirements;
