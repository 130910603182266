import { Box, CircularProgress, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import BottomPGDECard from "./BottomPGDECard";

function GeneralInfo({ data, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  return (
    <Fragment>
      {loading ? (
        <div className="center-loading">
          <CircularProgress sx={{ width: "20px" }} />
        </div>
      ) : (
        <div className="pgde_wrapper">
          {/* <h4 style={{ marginBottom: "10px" }}>Objectives of the Program:</h4>
      <ol>
        <li>
          To train and upgrade all qualified serving Grade II and NCE teachers
          to Degree level.
        </li>
        <li>
          To improve the basic background of those who may wish to pursue their
          studies at higher levels, and to help produce the required number of
          quality Degree holding teachers for the successful implementation of
          the Universal Basic Education Programme and the needed teachers at the
          senior secondary school level.
        </li>
      </ol> */}

          <div
            className="pgde_wrapper_content"
            dangerouslySetInnerHTML={{
              __html: data?.general_info_body || "",
            }}
          />

          {/* <h4>Target Group</h4>
      <p style={{ fontSize: "16px" }}>
        The programme is targeted at graduates of other disciplines who do not
        possess a professional teaching qualification but are currently teaching
        or intend to teach in future.
      </p> */}

          <BottomPGDECard
            fee={data?.application_fee_amount}
            isPhone={isPhone}
            semester={data?.course_semester_duration}
            year={data?.course_semester_years}
            note={data?.note}
          />
        </div>
      )}
    </Fragment>
  );
}

export default GeneralInfo;
