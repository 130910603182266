import { Box } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { RequirementsCard } from "../../../util/util";
import BottomPGDECard from "../pgde/BottomPGDECard";
import { ReplaceSepcialChars } from "../../../util/escapeChar";

function RequirementNce({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const cardDetails = [
    {
      title: content?.first_requirement,
      years: content?.first_minimum,
    },

    {
      title: content?.second_requirement,
      years: content?.second_minimum,
    },
    {
      title: content?.third_requirement,
      years: content?.third_minimum,
    },
    {
      title: content?.fourth_requirement,
      years: content?.fourth_minimum,
    },
    {
      title: content?.fifth_requirement,
      years: content?.fifth_minimum,
    },
  ];
  return (
    <div style={{ marginBottom: "200px" }}>
      <RequirementsCard
        cardDetails={cardDetails}
        requireText={content?.requirements_body}
        isPhone={isPhone}
      />

      <Box
        sx={{
          marginTop: "2rem",
        }}
        className="requirements_additional_entry_wrapper"
      >
        <p>{content?.other_requirement}</p>
      </Box>

      <BottomPGDECard
        fee={content?.application_fee}
        isPhone={isPhone}
        semester={content?.programme_duration}
        year={content?.programme_year}
        note={content?.note}
      />
    </div>
  );
}

export default RequirementNce;
