import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAchievementContent,
  getCentersContent,
  getFunctionContent,
  getGovernanceContent,
  getManagementContent,
  getStructureContent,
} from "../redux/aboutUs/aboutUsAction";
import { getNewsContent } from "../redux/news/NewsAndMediaAction";
import {
  getAdeContent,
  getAdeeContent,
  getBedContent,
  getCapacityContent,
  getNceContent,
  getPgdeContent,
  getProgrammeContent,
  getPttpContent,
} from "../redux/programme/ProgrammeAction";

function AuthProvider({ children }) {
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     dispatch(getStructureContent());
  //     dispatch(getGovernanceContent());
  //     dispatch(getAchievementContent());
  //     dispatch(getFunctionContent());
  //     dispatch(getCentersContent());
  //     dispatch(getManagementContent());
  //   }, [dispatch]);
  return <Fragment>{children}</Fragment>;
}

export default AuthProvider;
