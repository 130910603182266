import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useMediaQuery } from "react-responsive";
import BottomPGDECard from "../pgde/BottomPGDECard";
import { Link } from "react-router-dom";

function GeneralInfoNce({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const cardDetails = [
    {
      title: content?.first_specialization,
    },
    {
      title: content?.second_specialization ? content.second_specialization.replace(/\r?\n|\r/g, "") : "",
    },
    {
      title: content?.third_specialization,
    },
    { title: content?.fourth_specialization },
    {
      title: content?.fifth_specialization,
    },
    { title: content?.sixth_specialization },
];

  return (
    <div className="nce_general_wrapper">
      {
        loading ? (
          <div className="center-loading">
            <CircularProgress sx={{ width: "20px" }} />
          </div>
        ) : (
          <div
            className="nce_wrapper_content"
            dangerouslySetInnerHTML={{
              __html: content?.general_info_body || "",
            }}
          />
        )

        /* <h4 style={{ margin: "30px 0px 10px" }}>Objectives of the Program:</h4>
      <ol>
        <li>
          To train and upgrade all qualified serving Grade II and NCE teachers
          to Degree level.
        </li>
        <li>
          To improve the basic background of those who may wish to pursue their
          studies at higher levels, and to help produce the required number of
          quality Degree holding teachers for the successful implementation of
          the Universal Basic Education Programme and the needed teachers at the
          senior secondary school level.
        </li>
      </ol> */
      }
      <Box>
        <h4>Specialisations:</h4>
        <Box className="nti_prog_card_wrapper">
          {cardDetails.map((card, key) => (
            <Box key={key} className="nti_prog_cards_card">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "40px",
                  color: "#2E2E2E",
                }}
              >
                {card.title}
              </Typography>
            </Box>
          ))}
          <Link to={'/programmes/nce/courses'}>
          <button
          style={{
            width: "100%",
            height: "44px",
            color: "#fff",
            background: "#00C853",
            border: "none",
            outline: "none",
            cursor: "pointer",
            fontSize:18
          }}
        >
          More
        </button>
          </Link>
               
        </Box>
 
      </Box>

      <BottomPGDECard
        fee={content?.application_fee}
        isPhone={isPhone}
        semester={content?.programme_duration}
        year={content?.programme_year}
        note={content?.note}
      />
    </div>
  );
}

export default GeneralInfoNce;
