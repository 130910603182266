import React from "react";
import Pics from "../../assets/images/about-history.jpg";
import "../../css/AboutUs.css";

function AboutNTI() {
  return (
    <div className="about_nti_wrapper">
      <section className="about_nti_introduction">
        <h4>Introduction</h4>
        <p>
          The National Teachers’ Institute, Kaduna is a single mode distance
          education institution dedicated to teacher training. It was
          established in 1976 by the Federal Government primarily because of the
          pressing needs in the country for trained and qualified teaching staff
          at all levels of the educational system. Act No. 7 of 10th April 1978
          establishing the Institute charged it among others with the
          responsibility of: providing courses of instruction leading to the
          development, upgrading and certification of teachers as specified in
          the relevant syllabus using distance education techniques.
        </p>
        <img
          src={Pics}
          alt="NTI Building"
          // style={{ marginBottom: "70px", width: "100%" }}
          className="about_section_images"
        />
      </section>
      <section className="about_nti_history">
        <h4>Our History</h4>
        <p>
          For over 41 years the National teaching institute has opened the doors
          for teachers and education professionals, undertaking higher education
          or professional development. We are proud and committed to helping
          teachers succeed on their study journeys. We are committed to making
          high quality, enjoyable education available to all. NTI recognizes
          students’ need for flexible learning schedules that fit into their
          lives, and our goal is to provide an education that fits your
          lifestyle. We also believe that teachers are the key to a great
          education, and a great education can open up opportunity and
          possibility for young people. The National Teaching Institute helps
          teachers rediscover their love of their subject, inspiring them to
          bring the latest and cutting edge thinking into their classrooms while
          supporting them to make lasting improvements in what and how they
          teach.
        </p>
      </section>

      <section className="about_nti_vision">
        <h4>Our Vision</h4>
        <p>
          The vision of the Institute is to enhance the professional skills of
          serving teachers for high quality education delivery at primary and
          secondary education levels with a view to uplifting the standard of
          the education system of the country.
        </p>
      </section>
      <section className="about_nti_mission">
        <h4>Our Mission Statement</h4>
        <p>
          To upgrade/update teachers knowledge and skills in curriculum
          implementation while instilling in them the virtues of dedication,
          loyalty, commitment, discipline and resourcefulness. <br />
          <br />
          The Institute’s vision shall be realized through the pursuit of the
          following goals:
        </p>

        <ul>
          <li>
            Producing teachers trained and oriented to meet the challenges of
            twenty first century Nigerian society;
          </li>
          <li>
            Nurturing a commitment to lifelong learning that is creative,
            innovative, and responsive to changes in the world of knowledge and
            the changing needs;
          </li>
          <li>
            Designing and enriching subject content to ensure that the
            teacher-trainees attain a high level of mastery of the subject
            matter;
          </li>
          <li>
            Building the capacity of teacher- trainees in the use of effective
            communication skills and relevant technologies for the delivery of
            quality education;
          </li>
          <li>
            Strengthening the Institute’s monitoring, evaluation and feedback
            mechanism to ensure effective and efficient service delivery;
          </li>
          <li>
            Developing capacity for effective selection and deployment of
            relevant technology including Information and Communication
            Technology for effective Distance Learning delivery;
          </li>
          <li>
            Establishing effective research capability in order to ensure that
            all the Institute’s programmes are based on scientifically
            established grounds in terms of who needs training and the content
            of training required;
          </li>
          <li>
            Reviewing and revitalizing in-service training and retraining
            programme for all categories of teachers, school administrators and
            school supervisors;
          </li>
          <li>
            Regularly reviewing and restructuring curricula of all the
            Institute’s programmes to bring them in line with changing
            realities;
          </li>
        </ul>
      </section>

      <section className="about_nti_setup">
        <h4>Why the NTI was Setup</h4>
        <p>
          The National Teachers’ Institute was setup to serve as a Distant
          Learning Educational Institution in order to provide additional means
          of tackling the following challenges facing Teacher Education in
          Nigeria:-
        </p>
        <ul>
          <li>
            The need to trully profesionalised the teaching profession at all
            levels.
          </li>
          <li>
            The need to curb the worrisome shortages of professional Teachers
            nation-wide.
          </li>
          <li>
            The need to provide serving teachers the opportunity to further,
            upgrade / update their qualification and professional competence
            without necessarily leaving their jobs.
          </li>
          <li>
            Finally, the need to provide ALL the above at minimum costs to both
            the Students and the Education Authorities
          </li>
        </ul>
      </section>
    </div>
  );
}

export default AboutNTI;
