import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";

function FeesBed({ content, loading }) {
  const [tutionData, setTutionData] = useState([]);
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const tableHead = ["Tuition fees", "Semester 1", "Semester 2"];
  const tableHead2 = ["Other fees", "Amount"];
  const tableHead3 = ["Admission form", "Semester 1", "Semester 2"];

  useEffect(() => {
    const fetchTuitionData = async () => {
      try {
        const response = await axios.get(
          "https://cms.nti.edu.ng/api/programme-bed"
        );
        const data = response.data;
        setTutionData(data.tutionFees);
      } catch (error) {
        console.error("Error fetching tuition data:", error);
      }
    };

    fetchTuitionData();
  }, []);

  const data = [
    {
      level: "100L",
      semester1: "56,000",
      semester2: "50,000",
    },

    {
      level: "200L",
      semester1: "50,000",
      semester2: "50,000",
    },
    {
      level: "300L",
      semester1: "56,000",
      semester2: "60,000",
    },
    {
      level: "400L",
      semester1: "60,000",
      semester2: "60,000",
    },
  ];

  const data2 = [
    {
      fees: "Academic Transcript (Excluding Courier Cost)",
      amount: "3,000",
    },

    {
      fees: "Transfer Charges",
      amount: "2,000",
    },
    {
      fees: "Change of Name",
      amount: "1,000",
    },
    {
      fees: "Carry Over Charges",
      amount: "7,000",
    },
    {
      fees: "Late Registration Penalty Charges",
      amount: "4,000",
    },
    {
      fees: "Examination Fee",
      amount: "1,000",
    },
  ];

  const data3 = [
    {
      type: "Admission form",
      semester1: "5,500 ",
      semester2: "-",
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#344A3D",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFDFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // const renderTutionDataOne = () => {
  //   return(
  //     tutionData.map((data, index) => (
  //       <TableRow key={index}>
  //       <TableCell>{data.tuition_row_one}</TableCell>
  //       <TableCell>{data.amount_first_semester}</TableCell>
  //       <TableCell>{data.amount_second_semester}</TableCell>
  //     </TableRow>
  //     ))
  //   )
  // }
  return (
    <Box sx={{ width: "100%", marginBottom: "200px" }}>
      <h4 style={{ marginTop: "1rem" }}>Fees</h4>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isPhone ? "column" : "row",
          gap: "20px",
        }}
      >
        <Box sx={{ width: isPhone ? "100%" : "50%", marginBottom: "35px" }}>
          <Paper
            sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
          >
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHead3 &&
                      tableHead3.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.admissionFees?.map((data, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data.admission_fee_name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data.admission_fee_amount_first_semester}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data.admission_fee_amount_second_semester}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper
            sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
          >
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHead &&
                      tableHead.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tutionData.map((data, key) => (
                    <>
                      <StyledTableRow key={key}>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_one}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_one_first_semester}
                          {/* 20,000 */}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_one_second_semester}
                          {/* 30,000 */}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_two}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_two_first_semester}
                          {/* 20,000 */}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_two_second_semester}
                          {/* 30,000 */}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_three}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_three_first_semester}
                          {/* 20,000 */}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_three_second_semester}
                          {/* 30,000 */}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_four}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_four_first_semester}
                          {/* 20,000 */}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data.tuition_row_four_second_semester}
                          {/* 30,000 */}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* {!isPhone ? (
        <Paper
          sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
        >
          <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHead &&
                    tableHead.map(
                      (td, key) =>
                        Boolean(td) && (
                          <StyledTableCell
                            key={key}
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {td}
                          </StyledTableCell>
                        )
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((data, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell
                      style={{
                        minWidth: 170,
                      }}
                    >
                      {data.level}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        minWidth: 170,
                      }}
                    >
                      {data.semester1}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        minWidth: 170,
                      }}
                    >
                      {data.semester2}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : null} */}
        </Box>
        <Box sx={{ width: isPhone ? "100%" : "50%" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHead2 &&
                      tableHead2.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.otherFees?.map((data, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.amount}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>

      {/* <BottomPGDECard
    fee={5000}
    isPhone={isPhone}
    semester={2}
    year={"15months"}
  /> */}
    </Box>
  );
}

export default FeesBed;
