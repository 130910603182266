import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import BottomPGDECard from "../pgde/BottomPGDECard";

function GeneralInfoBed({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  const cardDetails = [
    {
      title: content?.first_specialization,
    },

    {
      title: content?.second_specialization,
    },
    {
      title: content?.third_specialization,
    },
    { title: content?.fourth_specialization },
    {
      title: content?.fifth_specialization,
    },
    { title: content?.sixth_specialization },
    { title: content?.seventh_specialization },
  ];

  return (
    <div className="bed_wrapper">
      {
        loading ? (
          <div className="center-loading">
            <CircularProgress sx={{ width: "20px" }} />
          </div>
        ) : (
          <div
            className="bed_wrapper_content"
            dangerouslySetInnerHTML={{
              __html: content?.general_info_body || "",
            }}
          />
        )
        /* <h4 style={{ marginBottom: "10px" }}>Objectives of the Program:</h4>
      <ol>
        <li>
          To train and upgrade all qualified serving Grade II and NCE teachers
          to Degree level.
        </li>
        <li>
          To improve the basic background of those who may wish to pursue their
          studies at higher levels, and to help produce the required number of
          quality Degree holding teachers for the successful implementation of
          the Universal Basic Education Programme and the needed teachers at the
          senior secondary school level.
        </li>
      </ol>
      <Box
        sx={{
          marginTop: "2rem",
        }}
      >
        <h4>Candidates are to select from among the following:</h4>
        <Box
          sx={{ display: "flex", flexDirection: isPhone ? "column" : "row" }}
        >
          <ul>
            <li>B.A. Ed (Primary)</li>
            <li>B.A. Ed (Social Studies)</li>
          </ul>
          <ul>
            <li>B.A. Ed (English)</li>
            <li>B.Sc. Ed (Mathematics)</li>
          </ul>
          <ul>
            <li>B.Sc. Ed (Integrated Science)</li>
            <li>B.Sc. Ed(Physical and Health Education)</li>
          </ul>
        </Box>
      </Box> */
      }
      <Box>
        <h4>Specialisations:</h4>
        <Box className="nti_prog_card_wrapper">
          {cardDetails.map((card, key) => (
            <Box key={key} className="nti_prog_cards_card">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "40px",
                  color: "#2E2E2E",
                }}
              >
                {card.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <BottomPGDECard
        fee={content?.application_fee_amount}
        isPhone={isPhone}
        semester={content?.course_semester_duration}
        year={content?.course_semester_years}
        note={content?.note}
      />
    </div>
  );
}

export default GeneralInfoBed;
