import React, { useEffect } from "react";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Pics from "../../assets/images/about_nti_zonal.png";
import Accordion from "../Accordion";
import { useSelector, useDispatch } from "react-redux";
import { getZonalOffices } from "../../redux/aboutUs/aboutUsAction";

function ServicomContact({ content, loading }) {
  const { zonalOffices, isLoading } = useSelector((state) => state.about);
  const dispatch = useDispatch();
  const headquatersTableData = [
    {
      designation: "Director & Chief Executive",
      department: "Directorate",
      names: "Prof. Garba Musa Maitafsir",
      mobile: "08036861432",
      email: "",
    },
    {
      designation: "Nodal Officer",
      department: "Quality Assurance & ICT",
      names: "Mrs. Ubaida Faruk Shehu",
      mobile: "08036217233",
      email: "yarsarki11@gmail.com ",
    },
    {
      designation: "Charter Desk Officer",
      department: "Quality Assurance & ICT",
      names: "Lame Adamu Abubakar",
      mobile: "08032578657",
      email: "adamugarbalame@gmail.com",
    },
    {
      designation: "Customer Relations/Complaints Desk Officer",
      department: "Quality Assurance & ICT",
      names: "Muhammed Bello",
      mobile: "08063997700",
      email: "ibrobello93@gmail.com",
    },
    {
      designation: "Service Improvement Desk Officer",
      department: "Quality Assurance & ICT",
      names: "Adeleke, Saheed Temitope",
      mobile: "08034047932",
      email: "asta4life@yahoo.com",
    },
    {
      designation: "Support Staff",
      department: "Quality Assurance & ICT",
      names: "Baiye John Eric",
      mobile: "08064211621",
      email: "precious4eric@gmail.com",
    },
    {
      designation: "Support Staf",
      department: "Quality Assurance & ICT",
      names: "Hayatu Abubakar",
      mobile: "07037886097",
      email: "hayatuabubakar1975@gmail.com",
    },
  ];

  const departmentalTableData = [
    {
      department: "Quality Assurance & ICT",
      names: "Usman Mu’azu",
      mobile: "08172611512",
      email: "muazuusman75@gmail.com",
    },
    {
      department: "Academic Services",
      names: "Ahmed Isah",
      mobile: "08161156518",
      email: "ahmadisahahmad8@gmail.com",
    },
    {
      department: "Field Operations and Students’ Services",
      names: "Aliyu Adamu",
      mobile: "08034888095",
      email: "aliuadamu67@gmail.com",
    },
    {
      department: "Registry",
      names: "Lemsu Auta",
      mobile: "08065502630",
      email: "",
    },
    {
      department: "Examination",
      names: "Hadiza Isah Mohammed",
      mobile: "08035878450",
      email: "hadizaisa.hm@gmail.com",
    },
    {
      department: "Directorate",
      names: "Ja’afar Hussaini Aliyu",
      mobile: "08038756882",
      email: "jaf4salam@yahoo.com",
    },

    {
      department: "Bursary",
      names: "Mercy Mantoe",
      mobile: "08036422002",
      email: "mercymarknti@yahoo.com",
    },
  ];

  const liaisonTableData = [
    {
      address:
        "No. F12b NPA Quarters, Osun Street by BPE, IBB Boulevard, Maitama, Abuja.",
      names: "Muhammed R. T. Bokabo",
      mobile: "07037765649",
      email: "mbokabo@gmail.com",
    },
  ];

  const zonalTableData = [
    {
      title: "North - Central Zone",
      tableContent: [
        {
          zonalOffice: "NORTH CENTRAL ZONE",
          names: "Mrs. Felicia F. Popoola",
          zonalOfficeAddress:
            "National Commission for Colleges of Education HQ, Abuja",
          phone: "0806 314 0823",
        },
        {
          zonalOffice: "FCT, ABUJA",
          names: "Mr. Yusuf Yahaya",
          zonalOfficeAddress:
            "Behind Assemblies of God Church, Jikwoyi Road, Karu",
          phone: "0803 563 7513",
        },
        {
          zonalOffice: "BENUE",
          names: "Dr. Dabu J. Terrumun",
          zonalOfficeAddress: "No. 37, Joseph Tarka Street, Markudi",
          phone: "0703 015 0341",
        },
        {
          zonalOffice: "KOGI",
          names: "Tijjani Yusuf",
          zonalOfficeAddress:
            "No. 6. Obaje Street, Behind NIWA  Headquarters, Lokoja",
          phone: "0806 781 5913",
        },
        {
          zonalOffice: "KWARA",
          names: "Mrs. Tinuade A. Adedokun",
          zonalOfficeAddress:
            "Asa Dam Road by Osere, Opposite National Pilot Newspaper, Ilorin",
          phone: "0806 089 1115",
        },
        {
          zonalOffice: "NASSARAWA",
          names: "Mr. Samaila Audu Abubakar",
          zonalOfficeAddress:
            "Shendam road, adjacent Customary Court of Appeal Lafia, Nasarawa State.",
          phone: "0803 665 4401",
        },
        {
          zonalOffice: "NIGER",
          names: "Mr. Shehu Garba",
          zonalOfficeAddress: "2nd Floor, Federal Secretariat Complex, Minna",
          phone: "0806 251 1566",
        },
        {
          zonalOffice: "PLATEAU",
          names: "Mrs. Viko John Martina",
          zonalOfficeAddress:
            "Ministry of Works Compound by Zoo Road, Ahmadu Bello Road, Jos",
          phone: "0803 918 3223",
        },
      ],
    },

    {
      title: "North - East Zone",
      tableContent: [
        {
          zonalOffice: "NORTH EAST ZONE",
          names: "Íbrahim Sani",
          zonalOfficeAddress:
            "Federal Low Cost, Near Federal Inland Revenue Service, Bauchi ",
          phone: "07065017672",
        },
        {
          zonalOffice: "ADAMAWA",
          names: "Aishatu Usman",
          zonalOfficeAddress:
            "After Open University, Army Barracks Road, Jimeta,Yola",
          phone: "08033489860",
        },
        {
          zonalOffice: "BAUCHI",
          names: "Mr. Yahaya Saleh Aliyu",
          zonalOfficeAddress: "2nd Floor, Federal Secretariat Complex, Bauchi",
          phone: "0806 573 7753",
        },
        {
          zonalOffice: "BORNO",
          names: "Mr. Modu Kingimi",
          zonalOfficeAddress: "No. 27B. Baga Road, Maiduguri",
          phone: "0706 803 0733",
        },
        {
          zonalOffice: "GOMBE",
          names: "Mr. Kabiru Rabiu",
          zonalOfficeAddress:
            "Dukku Road, Off Federal Low Cost Junction, Gombe",
          phone: "0803 569 5097",
        },
        {
          zonalOffice: "TARABA",
          names: "Mr. Saidu Zakari Yusuf",
          zonalOfficeAddress:
            "140, Off Rev. Jolly Nyame Way, GRA, Opposite Millennium Suites, Jalingo.",
          phone: "0803 072 0028",
        },
        {
          zonalOffice: "YOBE",
          names: "Mr. Dahiru H. Fari",
          zonalOfficeAddress: "Federal Secretariat Damaturu",
          phone: "0803 482 1763",
        },
      ],
    },
    {
      title: "North - West Zone",
      tableContent: [
        {
          zonalOffice: "NORTH WEST ZONE",
          names: "Mr. Yakubu Abbas",
          zonalOfficeAddress:
            "Murtala Mohammed New Library Complex, Ahmadu Bello Way, Kano",
          phone: "0703 330 5979",
        },
        {
          zonalOffice: "JIGAWA",
          names: "Bashir Kawu",
          zonalOfficeAddress: "Takur, Dutse",
          phone: "0803 384 4550",
        },
        {
          zonalOffice: "KADUNA",
          names: "Salmanu N. Maiwada",
          zonalOfficeAddress:
            "National Teachers Institute, KM 5, Kaduna-Zaria Express Way, Rigachikun Kaduna",
          phone: "08135608330",
        },
        {
          zonalOffice: "KANO",
          names: "Mr. Muktar Suleiman",
          zonalOfficeAddress:
            "Murtala Mohammed New Library Complex, Ahmadu Bello Way, Kano",
          phone: "0803 559 8301",
        },
        {
          zonalOffice: "KATSINA",
          names: "Abbas Muhammad Dodo",
          zonalOfficeAddress: "Yahaya Madaki Road, Katsina",
          phone: "0803 504 0359",
        },
        {
          zonalOffice: "KEBBI",
          names: "Ibrahim   Muhammad Yabo",
          zonalOfficeAddress: "Haliru Abdu Secretariat, Birnin Kebbi",
          phone: "08149051994",
        },
        // {
        //   zonalOffice: "NORTH CENTRAL ZONE",
        //   names: "Mrs. Felicia F. Popoola",
        //   zonalOfficeAddress:
        //     "National Commission for Colleges of Education HQ, Abuja",
        //   phone: "0806 314 0823",
        // },
        {
          zonalOffice: "SOKOTO",
          names: "Mr. Abubakar Ibrahim Musa",
          zonalOfficeAddress:
            "Block 10, 2nd Floor, Shehu Kangiwa Secretariat, Sokoto",
          phone: "0803 474 3924",
        },
        {
          zonalOffice: "ZAMFARA",
          names: "Yakubu Lawal",
          zonalOfficeAddress:
            "Behind Ministry for Local Gov’t and Community Development, Sokoto Road, Bye-Pass, Gusau – Zamfara State",
          phone: "08069392199",
        },
      ],
    },
    {
      title: "South - East Zone",
      tableContent: [
        {
          zonalOffice: "SOUTH EAST ZONE",
          names: "Dr. Onukwabe O. Victoria",
          zonalOfficeAddress:
            "Off Dr. Williams Ugwuanyi Close, By CBN Road, New GRA, Trans Ekulu, Enugu",
          phone: "0803 285 1744",
        },
        {
          zonalOffice: "ABIA",
          names: "Mrs. Kate Ndidi Obanye",
          zonalOfficeAddress:
            "World Bank Quarters, Ikot Ekpene Road by Opet Filling Station, Umuahia.",
          phone: "0803 560 7517",
        },
        {
          zonalOffice: "ANAMBRA",
          names: "Mrs. Augustina O. Ekuenugo",
          zonalOfficeAddress:
            "Community Education Resource Centre (CERC), Okpuno Road, (By Community Sec Sch, Okpuno), Awka.",
          phone: "0703 325 6849",
        },
        {
          zonalOffice: "EBONYI",
          names: "Mr. Oko Okpara",
          zonalOfficeAddress: "Enugu-Abakaliki Road, Abakaliki",
          phone: "07035742401",
        },
        {
          zonalOffice: "ENUGU",
          names: "Onyia Jacinta Amaka",
          zonalOfficeAddress:
            "NEW Office Address Main Federal Secretariat Building at Independence Layout",
          phone: "0806 429 0283",
        },
        {
          zonalOffice: "IMO",
          names: "Mr. John Nwodu",
          zonalOfficeAddress: "Old State Secretariat, Orlu Road, Owerri",
          phone: "0803 368 8423",
        },
      ],
    },

    {
      title: "South - West Zone",
      tableContent: [
        {
          zonalOffice: "SOUTH WEST ZONE",
          names: "Mrs. Toyin Fape",
          zonalOfficeAddress:
            "Illesa-Owo Express Way, Opposite NNPC Mega Filling Station, Akure",
          phone: "0803 726 7994",
        },
        {
          zonalOffice: "EKITI",
          names: "Mrs. Sherifat Omodele Suleiman",
          zonalOfficeAddress: "Government College, Polytechnic Road, Ado-Ekiti",
          phone: "0803 599 1647",
        },
        {
          zonalOffice: "LAGOS",
          names: "Mr. Francis Edun",
          zonalOfficeAddress:
            "No. 51/53 Yaya Obatan Road by Excellence Roundabout, Ogba, Lagos",
          phone: "08167236908",
        },
        {
          zonalOffice: "ONDO",
          names: "Mr. Adewale B. Kolawole",
          zonalOfficeAddress:
            "Ilesa–Owo Express Way, Opposite NNPC Mega Filling Station, Akure",
          phone: "0803 423 1092",
        },
        {
          zonalOffice: "OGUN",
          names: "Mrs. Ogunleye Felicia",
          zonalOfficeAddress:
            "Old Secretariat, Behind CBN, Oko-Ilewo, Abeokuta",
          phone: "0806 277 8055",
        },
        {
          zonalOffice: "OSUN",
          names: "Adeleye Mumini Kayode",
          zonalOfficeAddress: "Government Technical College, Iwo Road, Osogbo",
          phone: "07030318312",
        },
        {
          zonalOffice: "OYO",
          names: "Dr (Mrs.) A.B. Oyinlola",
          zonalOfficeAddress:
            "No. 5. Olanipekun Street, near Oluranti Hospital, Off Iwo Road, Ibadan.NEW Address for permanent Office: NTC Road, Iyanganku Quarters, Okebola, Beside Okebola Comp. High School, Ibadan",
          phone: "0803 379 6481, 0802 561 3215",
        },
      ],
    },
    {
      title: "South - South Zone",
      tableContent: [
        {
          zonalOffice: "SOUTH SOUTH ZONE",
          names: "Mrs. Celina E. Ogbejiele",
          zonalOfficeAddress:
            "Benin Technical College Road, Ugbowo, Benin City",
          phone: "0803 454 9316",
        },
        {
          zonalOffice: "AKWA IBOM",
          names: "Udo-Aka Esua Okon",
          zonalOfficeAddress: "Federal Secretariat Complex, Uyo",
          phone: "08038776092",
        },
        {
          zonalOffice: "BAYELSA",
          names: "Mrs. Nicholas Veronica Temitayo",
          zonalOfficeAddress: "No. 6 School Road, Okutukutu, Yenagoa",
          phone: "0803 690 9580",
        },
        {
          zonalOffice: "CROSS RIVER",
          names: "Mr. Edeh James C.",
          zonalOfficeAddress:
            "Murtala Mohammed Highway, by TTC Bus Stop, beside National Open University of Nigeria (NOUN), Calabar Study Centre",
          phone: "0803 560 0698",
        },
        {
          zonalOffice: "DELTA",
          names: "Mrs. Ifeoma Aniegbunem",
          zonalOfficeAddress: "Federal Secretariat Complex, Asaba",
          phone: "0802 702 6051",
        },
        {
          zonalOffice: "EDO",
          names: "Mr. James Orumwense",
          zonalOfficeAddress:
            "Behind Iluobe Filling Station, Agbor Road, Benin City.",
          phone: "0803 846 9851",
        },
        {
          zonalOffice: "RIVERS",
          names: "Martha Audu   Iyang",
          zonalOfficeAddress: "Federal Secretariat Complex, Port Harcourt",
          phone: "08037025132",
        },
      ],
    },
  ];

  const headquatersTableHead = [
    "S/N",
    "NAMES",
    "DESIGNATION",
    "EMAIL",
    "PHONE NUMBER",
    "DEPARTMENT",
  ];
  const zonalTableHead = [
    "S/N",
    "ZONAL/STATE OFFICE",
    "NAMES",
    "ZONAL/STATE OFFICE ADDRESS",
    "PHONE ",
  ];
  const departmentalTableHead = [
    "S/N",
    "DEPARTMENT",
    "NAMES",
    "MOBILE NUMBER",
    "EMAIL",
  ];
  const liaisonTableHead = ["S/N", "NAMES", "PHONE NUMBER", "EMAIL", "ADDRESS"];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#46C35F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFDFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    dispatch(getZonalOffices());
  }, [dispatch]);
  return (
    <Box sx={{ width: "100%" }}>
      <div className="about_management_img_wrapper">
        <h4>Contact</h4>
        {/* <img
      src={Pics}
      alt=""
      className="about_section_images"
      className="about-us-setions-image"
    /> */}
      </div>

      <Accordion
        heading={"SERVICOM Contacts at Headquaters"}
        content={
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headquatersTableHead &&
                      headquatersTableHead?.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.servicom_hq_table?.map((bio, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                        component="th"
                        scope="row"
                      >
                        {key + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.designation}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.email}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.phone}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.department}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          // </Box>
        }
      />

      <Accordion
        heading={"Liaison Office FCT Abuja"}
        content={
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {liaisonTableHead &&
                      liaisonTableHead?.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.servicom_liason_table?.map((bio, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                        component="th"
                        scope="row"
                      >
                        {key + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.phone}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.email}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.address}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          // </Box>
        }
      />
      <Accordion
        heading={"Departmental SERVICOM contact"}
        content={
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {departmentalTableHead &&
                      departmentalTableHead?.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.departmental_contacts_table?.map((bio, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                        component="th"
                        scope="row"
                      >
                        {key + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.department}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.phone}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {bio?.email}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          // </Box>
        }
      />

      <Accordion
        heading={"ZONAL AND STATE COORDINATORS"}
        content={React.Children.toArray(
          zonalOffices?.map((zonal, key) =>
            isLoading ? (
              <Stack
                width={"100"}
                direction="row"
                alignItems={"center"}
                justifyContent="center"
              >
                <CircularProgress sx={{ color: "#46C35F", width: "20px" }} />
              </Stack>
            ) : (
              <Accordion
                key={key}
                heading={zonal.title}
                content={
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {zonalTableHead &&
                              zonalTableHead?.map(
                                (td, key) =>
                                  Boolean(td) && (
                                    <StyledTableCell
                                      key={key}
                                      style={{
                                        minWidth: 170,
                                      }}
                                    >
                                      {td}
                                    </StyledTableCell>
                                  )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {zonal?.tableContent?.map((bio, key) => (
                            <StyledTableRow key={key}>
                              <StyledTableCell
                                style={{
                                  minWidth: 170,
                                }}
                                component="th"
                                scope="row"
                              >
                                {key + 1}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {bio?.zonalOffice}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {bio?.names}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {bio?.zonalOfficeAddress}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {bio?.phone}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  // </Box>
                }
              />
            )
          )
        )}
      />

      <p
        style={{
          margin: "2rem 0rem",
        }}
      >
        If your complaint is unresolved or you are dissatisfied with the
        resolution use our online Complaint Form
        <a
          href="https://forms.office.com/r/PDgrBtFR5R"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          (https://forms.office.com/r/PDgrBtFR5R).
        </a>
        <p>"You can contact us Via our email servicom@nti.edu.ng.</p>
        {/* ; or download the form{" "}
        <a
          href="https://Forms\CustomerComplaintForm.docx"
          target="_blank"
          rel="noopener noreferrer"
        >
          (Forms\Customer Complaint Form.docx)
        </a>{" "}
        fill and forward by e-mail to: servicom@nti.edu.ng or drop at the
        SERVICOM Division, Quality Assurance Department, NTI Headquarters, KM 5
        Kaduna – Zaria Express Way, Rigachikun-Kaduna. */}
      </p>
    </Box>
  );
}

export default ServicomContact;
