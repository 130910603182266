import React from "react";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import BottomPGDECard from "../pgde/BottomPGDECard";

function FeePttp({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const tableHead = ["Admission form", "Semester 1", "Semester 2"];
  const tableHead2 = ["Other fees", "Amount"];
  const tableHead3 = ["Tuition fees", "Semester 1", "Semester 2"];
  const data = [
    {
      type: "Admission form",
      semester1: "2,500",
      semester2: "",
    },
  ];

  const data2 = [
    {
      fees: "Resit Charge",
      amount: "3,000",
    },
  ];
  const data3 = [
    {
      fee: "Tuition fees",
      semester1: "19,000",
      semester2: "24,000",
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#344A3D",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFDFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <Box sx={{ width: "100%", marginBottom: "200px" }}>
      <h4 style={{ marginTop: "1rem" }}>Fees</h4>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isPhone ? "column" : "row",
          gap: "20px",
        }}
      >
        <Box sx={{ width: isPhone ? "100%" : "50%", marginBottom: "35px" }}>
          <Paper
            sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
          >
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHead &&
                      tableHead.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.admissionFees?.map((data, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.admission_fee_name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.admission_fee_amount_first_semester}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.admission_fee_amount_second_semester}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {!isPhone ? (
            <Paper
              sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
            >
              <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {tableHead3 &&
                        tableHead3.map(
                          (td, key) =>
                            Boolean(td) && (
                              <StyledTableCell
                                key={key}
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {td}
                              </StyledTableCell>
                            )
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {content?.tuitionFee?.map((data, key) => (
                      <StyledTableRow key={key}>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data?.tuition_fee_name}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data?.tuition_fee_amount_first_semester}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            minWidth: 170,
                          }}
                        >
                          {data?.tuition_fee_amount_second_semester}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : null}
        </Box>
        <Box sx={{ width: isPhone ? "100%" : "50%" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHead2 &&
                      tableHead2.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.otherFees?.map((data, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.amount}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>

      <BottomPGDECard
        fee={content?.application_fee_amount}
        isPhone={isPhone}
        semester={content?.course_semester_duration}
        year={content?.course_semester_years}
        note={content?.note}
      />
    </Box>
  );
}

export default FeePttp;
