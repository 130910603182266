import { Box, Typography } from "@mui/material";
import React from "react";

function BottomPGDECard({ isPhone, fee, semester, year, note }) {
  console.log('nce semester', semester)
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isPhone ? "repeat(1,1fr)" : "repeat(3,1fr)",
        gap: "20px 20px",
        width: "100%",
        marginTop: "40px",
      }}
    >
      <Box
        className=""
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          boxShadow: "2px 5px 6px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Typography
          sx={{
            flex: 1,
            background: "#F6F6F6",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "40px",
            padding: "5px 0px",
            textAlign: "center",
          }}
        >
          Application fee
        </Typography>
        <Typography
          sx={{
            flex: 4,
            background: "#fff",
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "29px",
            padding: "25px 0px",
            textAlign: "center",
            color: "#000000"
          }}
        >
          {fee}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "40px",
          width: "100%",
          boxShadow: "2px 5px 6px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Typography
          sx={{
            flex: 1,
            background: "#F6F6F6",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "40px",
            padding: "5px 0px",
            textAlign: "center",
          }}
        >
          Course Duration:
        </Typography>
        <Box
          className=""
          sx={{
            flex: 4,
            background: "#fff",
            padding: "22px 20px",
          }}
        >
          <Box
            className=""
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Semesters:</p>
            <p>{semester}</p>
          </Box>
          <Box
            className=""
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Years:</p>
            <p>{year}</p>
          </Box>
        </Box>
      </Box>
      <Typography>{note}</Typography>
    </Box>
  );
}

export default BottomPGDECard;
