import React from "react";
import BottomPGDECard from "../pgde/BottomPGDECard";
import { useMediaQuery } from "react-responsive";
import { CircularProgress } from "@mui/material";

function GeneralAde({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  return (
    <div className="ade_general_wrapper" style={{ margin: "0px 0px 200px" }}>
      {
        loading ? (
          <div className="center-loading">
            <CircularProgress sx={{ width: "20px" }} />
          </div>
        ) : (
          <div
            className="bed_wrapper_content"
            dangerouslySetInnerHTML={{
              __html: content?.general_info_body || "",
            }}
          />
        )
        /* <h4 style={{ margin: "30px 0px 10px" }}>Objectives of the Program:</h4>

      <p>The objective of the Advanced Diploma in Education programme is;</p>
      <ol>
        <li>
          To help NCE holders upgrade their knowledge into some specialized
          Field in Education such as; School Supervision and Inspection, Early
          Childhood Education, Guidance & Counselling etc.
        </li>
      </ol> */
      }

      <BottomPGDECard
        fee={content?.application_fee}
        isPhone={isPhone}
        semester={content?.programme_duration}
        year={content?.programme_year}
        note={content?.note}
      />
    </div>
  );
}

export default GeneralAde;
