import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import BottomPGDECard from "../pgde/BottomPGDECard";

function GeneralPttp({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  const coreCourses = [
    {
      title: "Education",
    },

    {
      title: "English",
    },
    {
      title: "Mathematics",
    },
    { title: "Intergrated Science" },
    {
      title: "Social Studies",
    },
  ];
  const optionalCourses = [
    {
      title: "Physical and Health Education",
    },

    {
      title: "One Nigerian Language (Hausa, Igbo, or Yoruba)",
    },
    {
      title: "Christian Religious Studies (CRS)",
    },
    { title: "Islamic Religious Studies (IRS)" },
    {
      title: "Home Economics",
    },
    {
      title: "Agricultural Science",
    },
  ];
  return (
    <div className="pttp_general_wrapper">
      {/* <h4 style={{ margin: "20px 0px 10px" }}>Objectives of the Program:</h4>

      <p
        style={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: " #505050",
        }}
      >
        The objective of the Pivotal Teachers Training Programme (PTTP) is that,
        at the end of the programme, the participants would have;
      </p>
      <ol>
        <li>Acquired adequate knowledge of the subject matter</li>
        <li>Acquired pedagogical proficiency for effective teaching</li>
        <li>
          Imbibed relevant values and attitudes that would make them develop
          positive attitudes towards the teaching profession
        </li>
        <li>
          Acquired basic background for further continuous upgrading and
          updating programmes in the teaching profession
        </li>
      </ol>

      <h4>Courses Offered:</h4>
      <p
        style={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: " #505050",
          marginBottom: "2rem",
        }}
      >
        The Courses offered in the Pivotal Teachers Training Programme are
        divided into two category -: Core and Optional.
      </p> */}
      {loading ? (
        <div className="center-loading">
          <CircularProgress sx={{ width: "20px" }} />
        </div>
      ) : (
        <div
          className="pgde_wrapper_content"
          dangerouslySetInnerHTML={{
            __html: content?.general_info_body || "",
          }}
        />
      )}
      <Box sx={{ mt: "10px" }}>
        <h4>Core Courses</h4>
        <Box className="nti_prog_card_wrapper">
          {content?.coreCourse?.map((card, key) => (
            <Box key={key} className="nti_prog_cards_card">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "40px",
                  color: "#2E2E2E",
                }}
              >
                {card?.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ mt: "10px" }}>
        <h4>Optional Courses</h4>
        <Box className="nti_prog_card_wrapper">
          {content?.optionalCourse?.map((card, key) => (
            <Box key={key} className="nti_prog_cards_card">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "40px",
                  color: "#2E2E2E",
                }}
              >
                {card?.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <p
        style={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: " #505050",
          marginBottom: "2rem",
        }}
        dangerouslySetInnerHTML={{
          __html: content?.other_general_info || "",
        }}
      />

      {/* <b>Note:</b> Every student is expected to register for all the core
        subjects and at least two (2) others selected from the optional
        subjects. In addition, every student is expected to undergo 8 weeks
        supervised teaching practice exercise. */}

      {/* <h4>Other Information:</h4>

      <p
        style={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          color: " #505050",
        }}
      >
        Pivotal Teacher Training Programme (PTTP) is not the same as Teachers’
        Grade II. Pivotal Teachers’ Certificate is higher than Teachers’ Grade
        Two Certificate. Holders of PTC will be admitted into NTI NCE Year Two
        provided they have entry requirements for the NCE DLS.
      </p> */}

      <BottomPGDECard
        fee={content?.application_fee_amount}
        isPhone={isPhone}
        semester={content?.course_semester_duration}
        year={content?.course_semester_years}
        note={content?.note}
      />
    </div>
  );
}

export default GeneralPttp;
