import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import Accordion from "../Accordion";
import { useSelector, useDispatch } from "react-redux";
import { getZonalOffices } from "../../redux/aboutUs/aboutUsAction";

// interface ZonalOffice {
//   ZonalOffice: string;
//   names: string;
//   zonalOfficeAddress: string;
//   phone: string;
// }

function ZonalOffices() {
  const { zonalOffices, isLoading } = useSelector((state) => state.about);

  const zonalOfficeKeys = useMemo(
    () => Object.keys(zonalOffices),
    [zonalOffices]
  );

  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width: 900px)" });

  // const bioDetails = [
  //   {
  //     title: "North - Central Zone",
  //     tableContent: [
  //       {
  //         zonalOffice: "NORTH CENTRAL ZONE",
  //         names: "Mrs. Felicia F. Popoola",
  //         zonalOfficeAddress:
  //           "National Commission for Colleges of Education HQ, Abuja",
  //         phone: "0806 314 0823",
  //       },
  //       {
  //         zonalOffice: "FCT, ABUJA",
  //         names: "Mr. Yusuf Yahaya",
  //         zonalOfficeAddress:
  //           "Behind Assemblies of God Church, Jikwoyi Road, Karu",
  //         phone: "0803 563 7513",
  //       },
  //       {
  //         zonalOffice: "BENUE",
  //         names: "Dr. Dabu J. Terrumun",
  //         zonalOfficeAddress: "No. 37, Joseph Tarka Street, Markudi",
  //         phone: "0703 015 0341",
  //       },
  //       {
  //         zonalOffice: "KOGI",
  //         names: "Tijjani Yusuf",
  //         zonalOfficeAddress:
  //           "No. 6. Obaje Street, Behind NIWA  Headquarters, Lokoja",
  //         phone: "0806 781 5913",
  //       },
  //       {
  //         zonalOffice: "KWARA",
  //         names: "Mrs. Tinuade A. Adedokun",
  //         zonalOfficeAddress:
  //           "Asa Dam Road by Osere, Opposite National Pilot Newspaper, Ilorin",
  //         phone: "0806 089 1115",
  //       },
  //       {
  //         zonalOffice: "NASSARAWA",
  //         names: "Mr. Samaila Audu Abubakar",
  //         zonalOfficeAddress:
  //           "Shendam road, adjacent Customary Court of Appeal Lafia, Nasarawa State.",
  //         phone: "0803 665 4401",
  //       },
  //       {
  //         zonalOffice: "NIGER",
  //         names: "Mr. Shehu Garba",
  //         zonalOfficeAddress: "2nd Floor, Federal Secretariat Complex, Minna",
  //         phone: "0806 251 1566",
  //       },
  //       {
  //         zonalOffice: "PLATEAU",
  //         names: "Mrs. Viko John Martina",
  //         zonalOfficeAddress:
  //           "Ministry of Works Compound by Zoo Road, Ahmadu Bello Road, Jos",
  //         phone: "0803 918 3223",
  //       },
  //     ],
  //   },

  //   {
  //     title: "North - East Zone",
  //     tableContent: [
  //       {
  //         zonalOffice: "NORTH EAST ZONE",
  //         names: "Mr. Aminu Ibrahim Mairauta",
  //         zonalOfficeAddress:
  //           "Federal Low Cost, Near Federal Inland Revenue Service, Bauchi ",
  //         phone: "0803 073 1447",
  //       },
  //       {
  //         zonalOffice: "ADAMAWA",
  //         names: "Mr. Mustapha Raji",
  //         zonalOfficeAddress:
  //           "After Open University, Army Barracks Road, Jimeta,Yola",
  //         phone: "0803 617 2136",
  //       },
  //       {
  //         zonalOffice: "BAUCHI",
  //         names: "Mr. Yahaya Saleh Aliyu",
  //         zonalOfficeAddress: "2nd Floor, Federal Secretariat Complex, Bauchi",
  //         phone: "0806 573 7753",
  //       },
  //       {
  //         zonalOffice: "BORNO",
  //         names: "Mr. Modu Kingimi",
  //         zonalOfficeAddress: "No. 27B. Baga Road, Maiduguri",
  //         phone: "0706 803 0733",
  //       },
  //       {
  //         zonalOffice: "GOMBE",
  //         names: "Mr. Kabiru Rabiu",
  //         zonalOfficeAddress:
  //           "Dukku Road, Off Federal Low Cost Junction, Gombe",
  //         phone: "0803 569 5097",
  //       },
  //       {
  //         zonalOffice: "TARABA",
  //         names: "Mr. Saidu Zakari Yusuf",
  //         zonalOfficeAddress:
  //           "140, Off Rev. Jolly Nyame Way, GRA, Opposite Millennium Suites, Jalingo.",
  //         phone: "0803 072 0028",
  //       },
  //       {
  //         zonalOffice: "YOBE",
  //         names: "Mr. Dahiru H. Fari",
  //         zonalOfficeAddress: "Federal Secretariat Damaturu",
  //         phone: "0803 482 1763",
  //       },
  //     ],
  //   },
  //   {
  //     title: "North - West Zone",
  //     tableContent: [
  //       {
  //         zonalOffice: "NORTH WEST ZONE",
  //         names: "Mr. Yakubu Abbas",
  //         zonalOfficeAddress:
  //           "Murtala Mohammed New Library Complex, Ahmadu Bello Way, Kano",
  //         phone: "0703 330 5979",
  //       },
  //       {
  //         zonalOffice: "JIGAWA",
  //         names: "Bashir Kawu",
  //         zonalOfficeAddress: "Takur, Dutse",
  //         phone: "0803 384 4550",
  //       },
  //       {
  //         zonalOffice: "KADUNA",
  //         names: "Fatima Odaudo",
  //         zonalOfficeAddress:
  //           "National Teachers Institute, KM 5, Kaduna-Zaria Express Way, Rigachikun Kaduna",
  //         phone: "0806 563 5388",
  //       },
  //       {
  //         zonalOffice: "KANO",
  //         names: "Mr. Muktar Suleiman",
  //         zonalOfficeAddress:
  //           "Murtala Mohammed New Library Complex, Ahmadu Bello Way, Kano",
  //         phone: "0803 559 8301",
  //       },
  //       {
  //         zonalOffice: "KATSINA",
  //         names: "Abbas Dodo",
  //         zonalOfficeAddress: "Yahaya Madaki Road, Katsina",
  //         phone: "0803 504 0359",
  //       },
  //       {
  //         zonalOffice: "KEBBI",
  //         names: "Mr. Salmanu N. Maiwada",
  //         zonalOfficeAddress: "Haliru Abdu Secretariat, Birnin Kebbi",
  //         phone: "0813 560 8330",
  //       },
  //       {
  //         zonalOffice: "NORTH CENTRAL ZONE",
  //         names: "Mrs. Felicia F. Popoola",
  //         zonalOfficeAddress:
  //           "National Commission for Colleges of Education HQ, Abuja",
  //         phone: "0806 314 0823",
  //       },
  //       {
  //         zonalOffice: "SOKOTO",
  //         names: "Mr. Abubakar Ibrahim Musa",
  //         zonalOfficeAddress:
  //           "Block 10, 2nd Floor, Shehu Kangiwa Secretariat, Sokoto",
  //         phone: "0803 474 3924",
  //       },
  //       {
  //         zonalOffice: "ZAMFARA",
  //         names: " Mrs. Zainab Aliyu",
  //         zonalOfficeAddress:
  //           "Behind Ministry for Local Gov’t and Community Development, Sokoto Road, Bye-Pass, Gusau – Zamfara State",
  //         phone: "0806 595 5794",
  //       },
  //     ],
  //   },
  //   {
  //     title: "South - East Zone",
  //     tableContent: [
  //       {
  //         zonalOffice: "SOUTH EAST ZONE",
  //         names: "Dr. Onukwabe O. Victoria",
  //         zonalOfficeAddress:
  //           "Off Dr. Williams Ugwuanyi Close, By CBN Road, New GRA, Trans Ekulu, Enugu",
  //         phone: "0803 285 1744",
  //       },
  //       {
  //         zonalOffice: "ABIA",
  //         names: "Mrs. Kate Ndidi Obanye",
  //         zonalOfficeAddress:
  //           "World Bank Quarters, Ikot Ekpene Road by Opet Filling Station, Umuahia.",
  //         phone: "0803 560 7517",
  //       },
  //       {
  //         zonalOffice: "ANAMBRA",
  //         names: "Mrs. Augustina O. Ekuenugo",
  //         zonalOfficeAddress:
  //           "Community Education Resource Centre (CERC), Okpuno Road, (By Community Sec Sch, Okpuno), Awka.",
  //         phone: "0703 325 6849",
  //       },
  //       {
  //         zonalOffice: "EBONYI",
  //         names: "Mr. Oko Okpara",
  //         zonalOfficeAddress: "Enugu-Abakaliki Road, Abakaliki",
  //         phone: "07035742401",
  //       },
  //       {
  //         zonalOffice: "ENUGU",
  //         names: "Mrs Akeibiri Ebiboloukemi",
  //         zonalOfficeAddress:
  //           "Federal Secretariat Extension, 5, Ridge Way Road, GRA, Enugu",
  //         phone: "0806 429 0283",
  //       },
  //       {
  //         zonalOffice: "IMO",
  //         names: "Mr. John Nwodu",
  //         zonalOfficeAddress: "Old State Secretariat, Orlu Road, Owerri",
  //         phone: "0803 368 8423",
  //       },
  //     ],
  //   },

  //   {
  //     title: "South - West Zone",
  //     tableContent: [
  //       {
  //         zonalOffice: "SOUTH WEST ZONE",
  //         names: "Mrs. Toyin Fape",
  //         zonalOfficeAddress:
  //           "Illesa-Owo Express Way, Opposite NNPC Mega Filling Station, Akure",
  //         phone: "0803 726 7994",
  //       },
  //       {
  //         zonalOffice: "EKITI",
  //         names: "Mrs. Sherifat Omodele Suleiman",
  //         zonalOfficeAddress: "Government College, Polytechnic Road, Ado-Ekiti",
  //         phone: "0803 599 1647",
  //       },
  //       {
  //         zonalOffice: "LAGOS",
  //         names: "Dr. Kayode T. Jegede",
  //         zonalOfficeAddress:
  //           "No. 51/53 Yaya Obatan Road by Excellence Roundabout, Ogba, Lagos",
  //         phone: "0806 065 7414",
  //       },
  //       {
  //         zonalOffice: "ONDO",
  //         names: "Mr. Adewale B. Kolawole",
  //         zonalOfficeAddress:
  //           "Ilesa–Owo Express Way, Opposite NNPC Mega Filling Station, Akure",
  //         phone: "0803 423 1092",
  //       },
  //       {
  //         zonalOffice: "OGUN",
  //         names: "Mrs. Ogunleye Felicia",
  //         zonalOfficeAddress:
  //           "Old Secretariat, Behind CBN, Oko-Ilewo, Abeokuta",
  //         phone: "0806 277 8055",
  //       },
  //       {
  //         zonalOffice: "OSUN",
  //         names: "Mr. Francis Edun",
  //         zonalOfficeAddress: "Government Technical College, Iwo Road, Osogbo",
  //         phone: "0816 723 6908",
  //       },
  //       {
  //         zonalOffice: "OYO",
  //         names: "Dr (Mrs.) A.B. Oyinlola",
  //         zonalOfficeAddress:
  //           "No. 5. Olanipekun Street, near Oluranti Hospital, Off Iwo Road, Ibadan.NEW Address for permanent Office: NTC Road, Iyanganku Quarters, Okebola, Beside Okebola Comp. High School, Ibadan",
  //         phone: "0803 379 6481, 0802 561 3215",
  //       },
  //     ],
  //   },
  //   {
  //     title: "South - South Zone",
  //     tableContent: [
  //       {
  //         zonalOffice: "SOUTH SOUTH ZONE",
  //         names: "Mrs. Celina E. Ogbejiele",
  //         zonalOfficeAddress:
  //           "Benin Technical College Road, Ugbowo, Benin City",
  //         phone: "0803 454 9316",
  //       },
  //       {
  //         zonalOffice: "AKWA IBOM",
  //         names: "Mr. Abdullahi Ibn Sule",
  //         zonalOfficeAddress: "Federal Secretariat Complex, Uyo",
  //         phone: "0803 555 8218, 0708 211 5198",
  //       },
  //       {
  //         zonalOffice: "BAYELSA",
  //         names: "Mrs. Nicholas Veronica Temitayo",
  //         zonalOfficeAddress: "No. 6 School Road, Okutukutu, Yenagoa",
  //         phone: "0803 690 9580",
  //       },
  //       {
  //         zonalOffice: "CROSS RIVER",
  //         names: "Mr. Edeh James C.",
  //         zonalOfficeAddress:
  //           "Murtala Mohammed Highway, by TTC Bus Stop, beside National Open University of Nigeria (NOUN), Calabar Study Centre",
  //         phone: "0803 560 0698",
  //       },
  //       {
  //         zonalOffice: "DELTA",
  //         names: "Mrs. Ifeoma Aniegbunem",
  //         zonalOfficeAddress: "Federal Secretariat Complex, Asaba",
  //         phone: "0802 702 6051",
  //       },
  //       {
  //         zonalOffice: "EDO",
  //         names: "Mr. James Orumwense",
  //         zonalOfficeAddress:
  //           "Behind Iluobe Filling Station, Agbor Road, Benin City.",
  //         phone: "0803 846 9851",
  //       },
  //       {
  //         zonalOffice: "RIVERS",
  //         names: "Mr. Gamaliel Dogo",
  //         zonalOfficeAddress: "Federal Secretariat Complex, Port Harcourt",
  //         phone: "0810 088 2346",
  //       },
  //     ],
  //   },
  // ];
  // const bioDetails = [
  //   {
  //     title: "North - Central Zone",
  //     center: northCentral,
  //   },

  //   {
  //     title: "North - East Zone",
  //     center: northEast,
  //   },
  //   {
  //     title: "North - West Zone",
  //     center: northWest,
  //   },
  //   {
  //     title: "South - East Zone",
  //     center: southEast,
  //   },

  //   {
  //     title: "South - West Zone",
  //     center: southWest,
  //   },
  //   {
  //     title: "South - South Zone",
  //     center: southSouth,
  //   },
  // ];

  const tableHead = [
    "S/N",
    "ZONAL/STATE OFFICE",
    "NAMES",
    "ZONAL/STATE OFFICE ADDRESS",
    "PHONE ",
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#46C35F",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFDFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    dispatch(getZonalOffices());
  }, [dispatch]);

  return (
    <Box sx={{ width: "100%" }}>
      <div className="about_management_img_wrapper">
        <h4>Zonal & State Offices</h4>
        {/* <img
          src={Pics}
          alt=""
          className="about_section_images"
          className="about-us-setions-image"
        /> */}
      </div>

      <p>FIELD OPERATIONS AND STUDENTS’ SERVICES DEPARTMENT.</p>
      <p>
        <b>
          NAMES, ADDRESSES AND PHONE NUMBERS OF ZONAL AND STATE COORDINATORS AS
          AT 26TH SEPTEMBER, 2020
        </b>
      </p>

      <div className="table-table-wrapper"></div>

      {isLoading ? (
        <Stack
          width={"100"}
          direction="row"
          alignItems={"center"}
          justifyContent="center"
        >
          <CircularProgress sx={{ color: "#46C35F", width: "20px" }} />
        </Stack>
      ) : (
        <>
          {zonalOfficeKeys?.length > 0 ? (
            zonalOfficeKeys?.map((key, index) => (
              <Accordion
                key={index}
                heading={key.replace(/_/g, " ")}
                content={
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {tableHead &&
                              tableHead.map(
                                (td, index_a) =>
                                  Boolean(td) && (
                                    <StyledTableCell
                                      key={index_a}
                                      style={{
                                        minWidth: 170,
                                      }}
                                    >
                                      {td}
                                    </StyledTableCell>
                                  )
                              )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {zonalOffices[key]?.map((data, index_b) => {
                            const jsonData = data?.[key]?.replace(/\\/g, "");
                            const content = data?.[key]
                              ? JSON.parse(jsonData ?? "{}")
                              : data;
                            return (
                              <StyledTableRow key={index_b}>
                                <StyledTableCell
                                  style={{
                                    minWidth: 170,
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  {index_b + 1}
                                </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 170 }} >
                                  {content?.zonaloffice}
                                </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 170 }}>
                                  {content?.names}
                                </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 170 }}>
                                  {content?.zonalofficeaddress}
                                </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 170 }}>
                                  {content?.phone}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                }
              />
            ))
          ) : (
            <p>No data available</p>
          )}
        </>
      )}
    </Box>
  );
}

export default ZonalOffices;
