import React, { useState, useEffect } from "react";
import BottomPGDECard from "../pgde/BottomPGDECard";
import axios from "axios";

import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Box,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";

function FeeNce({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const tableHead = ["NCE Cycle 1", "Semester 1", "Semester 2"];
  const tableHead2 = ["NCE Cycle 2", "Semester 1", "Semester 2"];
  const tableHead3 = ["NCE Cycle 3", "Semester 1", "Semester 2"];
  const tableHead4 = ["NCE Cycle 4", "Semester 1", "Semester 2"];
  const tableHead5 = ["Other fees", "Amount"];
  const tableHead6 = ["Admission form", "Semester 1", "Semester 2"];
  const [programmeNceCycle2, setProgrammeNceCycle2] = useState([]);
  const [programmeNceCycle3, setProgrammeNceCycle3] = useState([]);
  const [programmeNceCycle4, setProgrammeNceCycle4] = useState([]);

  const data = [
    {
      type: "Admission Form",
      semester1: "2,500",
      semester2: "-",
    },

    {
      type: "Fee for Non-Practical Courses",
      semester1: "23,000",
      semester2: "17,000",
    },
    {
      type: "Total for Practical Course ITS/PHE CCA",
      semester1: "26,000",
      semester2: "20,000",
    },
  ];
  const data2 = [
    {
      type: "Fee for Non-Practical Courses",
      semester1: "23,000",
      semester2: "17,000",
    },
    {
      type: "Total for Practical Course ITS/PHE CCA",
      semester1: "26,000",
      semester2: "20,000",
    },
  ];
  const data3 = [
    {
      type: "Fee for Non-Practical Courses",
      semester1: "17,000",
      semester2: "17,000",
    },
    {
      type: "Total for Practical Course ITS/PHE CCA",
      semester1: "20,000",
      semester2: "20,000",
    },
  ];
  const data4 = [
    {
      type: "Fee for Non-Practical Courses",
      semester1: "23,000",
      semester2: "17,000",
    },
    {
      type: "Total for Practical Course ITS/PHE CCA",
      semester1: "26,000",
      semester2: "20,000",
    },
  ];

  const data5 = [
    {
      fees: "Academic Transcript (Excluding Courier Cost)",
      amount: "2,000",
    },

    {
      fees: "Transfer, Change of Name/Change of Course (each)",
      amount: "1,000",
    },
    {
      fees: "Replacement of ID Card",
      amount: "1,000",
    },
    {
      fees: "Carry Over Charges",
      amount: "3,000",
    },
    {
      fees: "Late Registration Penalty Charges",
      amount: "4,000",
    },
    {
      fees: "Result Verification Charges",
      amount: "1,000",
    },
  ];

  const data6 = [
    {
      type: "Admission form",
      semester1: "2,500 ",
      semester2: "-",
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#344A3D",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FBFDFF",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    const fetchProgrammeNceCycle2 = async () => {
      try {
        const response = await axios.get(
          "https://cms.nti.edu.ng/api/programme-nce"
        );
        const data = response.data;
        setProgrammeNceCycle2(data.nceCycle2);
      } catch (error) {
        console.error("Error fetching tuition data:", error);
      }
    };

    fetchProgrammeNceCycle2();
  }, []);

  useEffect(() => {
    const fetchProgrammeNceCycle3 = async () => {
      try {
        const response = await axios.get(
          "https://cms.nti.edu.ng/api/programme-nce"
        );
        const data = response.data;
        console.log(data.nceCycle3);
        setProgrammeNceCycle3(data.nceCycle3);
      } catch (error) {
        console.error("error", error);
      }
    };

    fetchProgrammeNceCycle3();
  }, []);
  useEffect(() => {
    const fetchProgrammeNceCycle4 = async () => {
      try {
        const response = await axios.get(
          "https://cms.nti.edu.ng/api/programme-nce"
        );
        const data = response.data;
        console.log(data.nceCycle4);
        setProgrammeNceCycle4(data.nceCycle4);
      } catch (error) {
        console.error("error", error);
      }
    };

    fetchProgrammeNceCycle4();
  }, []);
  return (
    <Box sx={{ width: "100%", marginBottom: "200px" }}>
      <h4 style={{ marginTop: "1rem" }}>Fees</h4>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isPhone ? "column" : "row",
          gap: "20px",
        }}
      >
        <Box sx={{ width: isPhone ? "100%" : "50%", marginBottom: "35px" }}>
          <Paper
            sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
          >
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHead6 &&
                      tableHead6.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.admissionFees?.map((data, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.admission_fee_name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.admission_fee_amount_first_semester}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.admission_fee_amount_second_semester}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper
            sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
          >
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHead &&
                      tableHead.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.nceCycle1?.map((data, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.semester_one}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.semester_two}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* PUT THE API HERE. */}
          {!isPhone ? (
            <Paper
              sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
            >
              <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {tableHead2 &&
                        tableHead2.map(
                          (td, key) =>
                            Boolean(td) && (
                              <StyledTableCell
                                key={key}
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {td}
                              </StyledTableCell>
                            )
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {programmeNceCycle2.map((data, key) => (
                      <>
                        <StyledTableRow key={key}>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_semester_one}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_semester_two}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_semester_one}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_semester_two}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : null}

          {!isPhone ? (
            <Paper
              sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
            >
              <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {tableHead3 &&
                        tableHead3.map(
                          (td, key) =>
                            Boolean(td) && (
                              <StyledTableCell
                                key={key}
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {td}
                              </StyledTableCell>
                            )
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {programmeNceCycle3.map((data, key) => (
                      <>
                        <StyledTableRow key={key}>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_semester_one}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_semester_two}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_semester_one}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_semester_two}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : null}
          {!isPhone ? (
            <Paper
              sx={{ width: "100%", overflow: "hidden", marginBottom: "35px" }}
            >
              <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {tableHead4 &&
                        tableHead4.map(
                          (td, key) =>
                            Boolean(td) && (
                              <StyledTableCell
                                key={key}
                                style={{
                                  minWidth: 170,
                                }}
                              >
                                {td}
                              </StyledTableCell>
                            )
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {programmeNceCycle4.map((data, key) => (
                      <>
                        <StyledTableRow key={key}>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_semester_one}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_one_semester_two}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_name}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_semester_one}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              minWidth: 170,
                            }}
                          >
                            {data.row_two_semester_two}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : null}
        </Box>
        <Box sx={{ width: isPhone ? "100%" : "50%" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHead5 &&
                      tableHead5.map(
                        (td, key) =>
                          Boolean(td) && (
                            <StyledTableCell
                              key={key}
                              style={{
                                minWidth: 170,
                              }}
                            >
                              {td}
                            </StyledTableCell>
                          )
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content?.otherFees?.map((data, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: 170,
                        }}
                      >
                        {data?.amount}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>

      <BottomPGDECard
        fee={content?.application_fee_amount}
        isPhone={isPhone}
        semester={content?.course_semester_duration}
        year={content?.course_semester_years}
        note={content?.note}
      />
    </Box>
  );
}

export default FeeNce;
