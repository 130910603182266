import { Box } from "@mui/material";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Arrow from "../../assets/icon/arrow_white.svg";
import Phone from "../../assets/icon/phone_home_page_icon.svg";
import "../../css/Home.css";
import Twitter from "../../assets/icon/twitter_icon.svg";
import Facebook from "../../assets/icon/facebook_icon.svg";
import Linkedln from "../../assets/icon/linkedIn_icon.svg";
import Youtube from "../../assets/icon/youtube_icon.svg";

function HomeHeaderContent({ setScreen, screen }) {
  const isPhone = useMediaQuery({ query: "(max-width: 1024px)" });
  const navigate = useNavigate();
  const social = [
    {
      title: "Twitter icon",
      icon: Twitter,
      link: "https://twitter.com/ntiedung",
    },
    {
      title: "Facebook icon",
      icon: Facebook,
      link: "https://www.facebook.com/ntiedung",
    },
    {
      title: "Youtube icon",
      icon: Youtube,
      link: "",
    },
    {
      title: "Linkedln icon",
      icon: Linkedln,
      link: "",
    },
  ];
  return (
    <div style={{ marginTop: "70px" }}>
      <div className="" style={{ display: "flex", gap: "40px" }}>
        {isPhone ? null : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="home_header_social_icon_divider" />
            <div className="home_header_social_icon_wrapper">
              {social.map((icon, key) => (
                <a href={icon.link} target="_blank" key={key}>
                  <img
                    src={icon.icon}
                    alt={icon.title}
                    className="home_header_social_icon"
                  />
                </a>
              ))}
            </div>
          </Box>
        )}
        <div className="">
          <p className="home_page_sub_heading">
            WELCOME TO NATIONAL TEACHERS’ INSTITUTE, KADUNA
          </p>
          <h1 className="home_page_heading">
            We are dedicated to quality education in Nigeria
          </h1>
          <button onClick={() => navigate("/about-us")} className="home_btn">
            READ MORE ABOUT US <img src={Arrow} alt="Arrow" />
          </button>
        </div>
      </div>
      <div className="call_us_wrapper">
        <div className="call_us">
          <img src={Phone} alt="Phone" />

          <div className="home_contact_info">
            <p style={{ marginBottom: "1rem" }}>CALL US NOW</p>
            <p>+234(0)90 6457 9779</p>
          </div>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {[1, 2].map((item) => (
            <button
              key={item}
              style={{
                width: "30px",
                height: "0px",
                background: screen === item ? "#46C35F" : "#E0E0E0",
                marginRight: item === 1 ? "1rem" : "",
                cursor: "pointer",
                outline: "none",
                border:
                  screen === item ? "1px solid #46C35F" : "1px solid #E0E0E0",
              }}
              onClick={() => setScreen(item)}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeHeaderContent;
