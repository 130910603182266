import React from "react";
import { RequirementsCard } from "../../../util/util";
import { useMediaQuery } from "react-responsive";
import BottomPGDECard from "../pgde/BottomPGDECard";

function RequirementAde({ content, loading }) {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const cardDetails = [
    {
      title: content?.first_requirement,
      years: content?.first_requirement_minimum,
    },
  ];
  return (
    <div style={{ marginBottom: "200px" }}>
      <RequirementsCard
        cardDetails={cardDetails}
        requireText={content?.requirements_body}
        isPhone={isPhone}
      />

      <p>{content?.other_requirement}</p>

      <BottomPGDECard
        fee={content?.application_fee}
        isPhone={isPhone}
        semester={content?.programme_duration}
        year={content?.programme_year}
        note={content?.note}
      />
    </div>
  );
}

export default RequirementAde;
