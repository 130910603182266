import React from "react";
import Pics from "../../assets/images/achievement.jpg";
import { useSelector } from "react-redux";

function Achievement() {
  // const { achievement } = useSelector((state) => state.about);

  return (
    // <section
    //   className="about_achievement"
    //   dangerouslySetInnerHTML={{
    //     __html:
    //       achievement?.content?.rendered
    //         .replace("[vc_row][vc_column][vc_column_text]", " ")
    //         .replace("[/vc_column_text][/vc_column][/vc_row]", " ") || "",
    //   }}
    // />
    <div className="about_achievement">
      <h4>Our Achievements</h4>
      {/* <img src={Pics} alt="" className="about_section_images" /> */}
      <section className="about_achievement_MDGs">
        <p>
          The NTI has successfully trained 749,158 teachers through the MDGs
          from 2006-2015. As the nation transits from the Millennium Development
          Goals (MDGs) to the Sustainable Development Goals (SDGs), it is
          necessary to state that the National Teachers Institute has even
          greater role to play. As next steps, the NTI intends to:
        </p>

        <ol>
          <li>
            Train Primary and JSS teachers on the concept and principles of
            SDGs,
          </li>
          <li>
            Train them to integrate the SDGs in the classroom teaching of the 4
            core subject areas of Basic Science and Technology, English
            Language, Mathematics and Social Studies
          </li>
        </ol>

        <p>
          Train in thematic areas to be selected from the following: Inclusive
          Education, Language Communication Skills, Effective Classroom
          Management Skills, Basic Teaching Methods and Techniques, Information
          and Communication Technologies (ICT), Open Educational Resources
          (OERs) and Massive Open Online Courses (MOOCs), School Based
          Assessment, Improvisation of Instructional Materials, Teaching Large
          Classes, Coping with Children with Special Needs (Learning
          Difficulties), Teaching in Small Rural Multi-grade Schools,
          Entrepreneurship education, and E-Learning. There is need to increase
          the allocation for the project.
        </p>
      </section>

      <section className="about_achievement_strategic_plan">
        <h4>NTI and the FME Ministerial Strategic Plan (2016-2019)</h4>
        <p>
          As a parastatal of the Federal Ministry of Education, the National
          Teachers Institute is ever ready to key into the plans and programmes
          of the Ministry. It is therefore gratifying to note that the NTI is
          strategically placed to play a critical role in the actualisation of
          the Draft 2016-2019 FME Ministerial Strategic Plan. The Plan is built
          on nine pillars, all of which are relevant to the NTI Mandate. More
          specifically, four are directly relevant, namely:
        </p>

        <ul>
          <li>Strengthening basic and secondary school education;</li>
          <li>
            Teacher education, capacity building and professional development;
          </li>
          <li>Quality and access in higher education; and</li>
          <li>E-learning</li>
        </ul>
      </section>
    </div>
  );
}

export default Achievement;
