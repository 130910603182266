import { Box } from "@mui/material";
import React from "react";

function NtiServicom({ content, loading }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
      className="servicom_nti"
      dangerouslySetInnerHTML={{
        __html: content?.general_info_body || "",
      }}

      /* <Box>
        <h4 style={{ fontSize: "20px", marginBottom: "10px" }}>SERVICOM</h4>
        <p>
          SERVICOM stands for Service Compact with all Nigerians. The pivot of
          its’ existence is mainly on the point that any service can only have
          one purpose, which is to meet the expectation of the person the
          service is aimed at (the customer). The Compact’s core provision says:
          “We dedicate ourselves to providing the basic services to which
          citizens are entitled to timely, fairly, honest, effective and
          transparent services”.
        </p>
      </Box>

      <Box>
        <h4 style={{ fontSize: "20px", marginBottom: "10px" }}>About Us</h4>
        <p>
          The NTI SERVICOM Division is saddled with the responsibilities of
          ensuring that Due Process and Compliance is followed in running the
          day-to-day activities of the Institute’s Departments, Divisions, Units
          as well as Zonal and States Offices to enhance productivity and
          efficiency. SERVICOM is one of the Divisions domiciled in the Quality
          Assurance and ICT Department of the Institute. The Director and Chief
          Executive of the Institute is the chairman of SERVICOM in the National
          Teachers’ Institute.
        </p>
      </Box>

      <Box>
        <h4 style={{ fontSize: "20px", marginBottom: "10px" }}>Activities</h4>
        <p>
          The SERVICOM Division is the customer hub of the Institute championing
          the task of ensuring that every single staff of the institute commits
          to attend to customers and the general publics' need promptly,
          courteously, professionally and excellently. The nature of our
          activities include responding to and resolving inquiries regarding
          admissions, registration issues, examination issues, omission of
          regular and carry-over scores; correction of names; harmonization of
          results; Statements of Results; Certificates, and etc.
        </p>
      </Box> */
    />
  );
}

export default NtiServicom;
