import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PaymentMethods from "./PaymentMethods";
import ProgSection from "./ProgSection";
import FeePttp from "./pttp/FeePttp";
import GeneralPttp from "./pttp/GeneralPttp";
import RequirementPttp from "./pttp/RequirementPttp";
import IMG from "../../assets/images/prog_pttp_img.svg";
import { getPttpContent } from "../../redux/programme/ProgrammeAction";
import { useDispatch, useSelector } from "react-redux";

function PTTP() {
  const [section, setSection] = useState("general");
  const [sectionTitle, setSectionTitle] = useState("General info");

  const [show, setShow] = useState(false);
  const { loading, pttp } = useSelector((state) => state.programme);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPttpContent());
  }, [dispatch]);
  return (
    <div style={{ padding: "1rem", width: "100%", marginBottom: "200px" }}>
      <ProgSection
        heading="Pivotal Teachers Training Programme"
        image={IMG}
        setShow={setShow}
        setSection={setSection}
        section={section}
        setSectionTitle={setSectionTitle}
        show={show}
        sectionTitle={sectionTitle}
      />
      <Grid item sx={{ width: "100%" }}>
        {section === "general" ? (
          <GeneralPttp content={pttp} loading={loading} />
        ) : section === "requirements" ? (
          <RequirementPttp content={pttp} loading={loading} />
        ) : section === "fees" ? (
          <FeePttp content={pttp} loading={loading} />
        ) : section === "payment" ? (
          <PaymentMethods content={pttp} loading={loading} />
        ) : (
          <GeneralPttp content={pttp} loading={loading} />
        )}
      </Grid>
    </div>
  );
}

export default PTTP;
