import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStructureContent } from "../../redux/aboutUs/aboutUsAction";

function Structure() {
  const { structure } = useSelector((state) => state.about);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStructureContent());
  }, []);

  console.log(structure);
  return (
    <section
      className="about_structure"
      dangerouslySetInnerHTML={{
        __html: structure?.our_structure_page || "",
      }}
    />
    // <section className="about_structure">
    //   <h4>NTI Structure</h4>
    //   <p>
    //     The Institute, with its headquarters in Kaduna, operates through a
    //     network of 6 Zonal and 37 State offices and over 400 study centres. The
    //     organizational structure of the Institute comprises:
    //   </p>
    //   <ol>
    //     <li>Office of the Director-General & Chief Executive</li>
    //     <li>Registry and Council Affairs</li>
    //     <li>Department of Field Operations & Students Services (FOSS)</li>
    //     <li>Examinations</li>
    //     <li>Quality Assurance and ICT</li>
    //     <li>Academic Services</li>
    //     <li>Bursary</li>
    //   </ol>
    //   <p>
    //     The Zonal Offices are located in Abuja (North Central), Bauchi (North
    //     East), Benin (South South), Enugu (South East), Ibadan (South West), and
    //     Kano (North West). The Institute has an office in each of the 36 States
    //     and FCT. Tutorials, examinations, tutor-student interaction,
    //     student-student interaction, counselling, etc take place in the Study
    //     Centres all over the country.
    //   </p>
    // </section>
  );
}

export default Structure;
