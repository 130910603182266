const BASE_URL = "https://cms.nti.edu.ng/api";

const API_ROUTES = {
  get_program_page_main_content: {
    route: "pages/80",
    method: "GET",
  },

  // new endpoints

  // news
  get_news: {
    route: "news-and-updates",
    method: "get",
  },

  // ABOUT US
  aboutUs: {
    route: "about-us",
    method: "get",
  },
  ourStructure: {
    route: "our-structure",
    method: "get",
  },

  get_function: {
    route: "our-function",
    method: "get",
  },

  get_zonal_offices: {
    route: "zonal-and-state-offices",
    method: "get",
  },

  get_home_page_slider: {
    route: "slider",
    method: "get",
  },

  // Programs
  get_program_pgde_content: {
    route: "programme-pgde",
    method: "GET",
  },
  get_program_bed_content: {
    route: "programme-bed",
    method: "GET",
  },
  get_program_nce_content: {
    route: "programme-nce",
    method: "GET",
  },
  get_program_ade_content: {
    route: "programme-ade",
    method: "GET",
  },
  get_program_adee_content: {
    route: "programme-adee",
    method: "GET",
  },
  get_program_pttp_content: {
    route: "programme-pttp",
    method: "GET",
  },
  get_program_capacity_content: {
    route: "capacity-building",
    method: "GET",
  },

  // servicom
  get_servicom_content: {
    route: "servicom",
    method: "GET",
  },
  get_servicom_contact_content: {
    route: "servicom-contacts",
    method: "GET",
  },

  // Contact us
  get_contact_us_content: {
    route: "contact-us",
    method: "GET",
  },
};

export { BASE_URL, API_ROUTES };
