import {
  AccountCircle,
  Email,
  Message,
  NoteAlt,
  Refresh,
} from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import PageContainer from "../component/PageContainer";
import Location from "../assets/icon/home_location_icon.svg";
import Mail from "../assets/icon/home_message_icon.svg";
import Whatsapp from "../assets/icon/whatsapp-icon.svg";
import Phone from "../assets/icon/home_phone_icon.svg";
import Map from "../assets/images/map.png";
import "../css/ContactUs.css";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";

function ContactUs() {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [loadingContact, setLoadingContact] = useState(false);

  const [contact, setContact] = useState("");
  const getContact = () => {
    setLoadingContact(true);
    fetch("https://cms.nti.edu.ng/api/contact-us")
      .then((res) => res.json())
      .then((res) => setContact(res?.general_info_body))
      .catch((err) => console.log(err))
      .finally(() => setLoadingContact(false));
  };

  useEffect(() => {
    getContact();
  }, []);
  return (
    <PageContainer>
      <Helmet>
        <title>Contact Us | National Teachers' Institute Kaduna</title>
      </Helmet>
      <section className="contact_section_wrapper">
        <div className="contact_info_section_address_wrapper">
          <div className="contct_address_wrapper">
            <h4 className="contact_section_h4">Contact Us</h4>
          
          </div>
          <div className="contact" dangerouslySetInnerHTML={{ __html: contact }}>

          </div>
          {/* <div className="contct_address_wrapper">
            <h4>
              <img src={Whatsapp} alt="Whatsapp" /> Whatsapp Number
            </h4>
            <p>09095450282</p>
          </div> */}
        </div>
        <div className="contact_form_wrapper">
          <h4>If you have any questions for us, we are happy to answer them</h4>
          <form className="contact_form">
            <TextField
              className="contact_textfield"
              id="input-with-icon-textfield"
              placeholder="Full name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle className="contact_icon_color" />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              variant="standard"
            />
            <TextField
              className="contact_textfield"
              id="input-with-icon-textfield"
              placeholder="Email address"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email className="contact_icon_color" />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              variant="standard"
            />
            <TextField
              className="contact_textfield contact_textfield_message"
              fullWidth
              id="input-with-icon-textfield"
              placeholder="Message"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Message className="contact_icon_color" />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              sx={{
                height: "200px",
              }}
              variant="standard"
            />
            <TextField
              className="contact_textfield"
              id="input-with-icon-textfield"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Refresh />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              variant="standard"
            />
            <TextField
              className="contact_textfield"
              id="input-with-icon-textfield"
              placeholder="Captcha"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <NoteAlt className="contact_icon_color" />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              variant="standard"
            />

            <button className="home_btn contact_btn" style={{ width: "100%" }}>
              SEND
            </button>
          </form>
        </div>
      </section>
      <div className="map-picture">
        <iframe
          src="https://maps.google.com/maps?q=KM%205%20Kaduna%20%E2%80%93%20Zaria%20Express%20Way,%20Rigachikun,%20Kaduna,%20Nigeria.&t=&z=13&ie=UTF8&iwloc=&output=embed"
          style={{
            marginLeft: isPhone ? "0rem" : "7rem",
            border: 0,
            marginBottom: "3rem",
            width: isPhone ? "100%" : "80%",
            height: 350,
          }}
          allowfullscreen=""
        ></iframe>
      </div>
    </PageContainer>
  );
}

export default ContactUs;
