import React from "react";
import Pics from "../../assets/images/governance.jpg";
import { useSelector } from "react-redux";

function Governance() {
  // const { governance } = useSelector((state) => state.about);

  return (
    // <section
    //   className="about_governance"
    //   dangerouslySetInnerHTML={{
    //     __html:
    //       governance?.content?.rendered
    //         .replace("[vc_row][vc_column][vc_column_text]", " ")
    //         .replace("[/vc_column_text][/vc_column][/vc_row]", " ") || "",
    //   }}
    // />
    <section className="about_governance">
      <h4>Governance</h4>
      {/* <img src={Pics} alt="" className="about_section_images" /> */}
      <p style={{ margin: "1rem 0rem" }}>
        Meet the people and institutions who make Nigerian Teachers Institute an
        industry leader.
      </p>

      <h4>Composition of the Governing Council</h4>

      <ul>
        <li>Chairman and another Federal Government Nominee</li>
        <li>One Member Representing the Fed. Min. of Education</li>
        <li>Three Members representing the NUT, NTA and FRCN</li>
        <li>Two Members representing the Universities</li>
        <li>
          One Commissioner of Education from each of the Six Geo-Political Zones
        </li>
        <li>The Director General / CEO</li>
        <li>The Registrar serves as the Secretary to the Council.</li>
      </ul>

      <h4>Duties of the Governing Council</h4>

      <ul>
        <li>
          To organize and provide programmes for the training, development,
          upgrading and certification of teachers.
        </li>
        <li>
          To carry out research in conjunction with other bodies on any matter
          relevant to the development of teacher education in the country.
        </li>
        <li>
          To conduct post-graduate courses and examinations in EDUCATION for
          graduate teachers.
        </li>
        <li>
          To formulate and initiate relevant policies and programmes towards
          enriching / improving the content and quality of Teacher education in
          Nigeria.
        </li>
      </ul>

      <h4>Other Duties</h4>

      <ul>
        <li>
          To assess from time to time, the training programmes offered by
          Institutions controlled by, or associated with the Institute with a
          view to ascertaining their professional competence.
        </li>
        <li>
          To offer such assistance, either alone or in co-operation with other
          educational bodies, as may be requested by those institutions
          controlled by or associated with the Institute.
        </li>
        <li>
          To foster and enhance international co-operation in the development of
          Teacher Education.
        </li>
        <li>
          To perform such other functions as are necessary or expedient for the
          full discharge of any of the functions of the Institute.
        </li>
      </ul>
    </section>
  );
}

export default Governance;
