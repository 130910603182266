import React, { useEffect, useState } from "react";
import HomeHeader from "./home/HomeHeader";
import { useLocation, useNavigate } from "react-router-dom";
import GeneralHeader from "./GeneralHeader";
import SideDrawer from "./SideDrawer";
import { Helmet } from "react-helmet-async";

function Header() {
  const [page, setPage] = useState("about-us");
  const [active, setActive] = useState(false);

  // const navigate = useNavigate();

  const location = useLocation();
  const [activeLink, setActiveLink] = useState("Home");

  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname;
      const index = path.slice(1).indexOf("/");
      if (index !== -1) {
        setPage(location?.pathname?.slice(1, index + 1));
      } else {
        setPage(location?.pathname?.slice(1));
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SV9ET6XWTT"
        ></script>
        <script type="text/javascript">
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-SV9ET6XWTT'); `}
        </script>
      </Helmet>

      {location.pathname === "/" ? (
        <HomeHeader
          setActive={setActive}
          setActiveLink={setActiveLink}
          activeLink={activeLink}
        />
      ) : (
        <GeneralHeader
          setActive={setActive}
          setActiveLink={setActiveLink}
          activeLink={activeLink}
        />
      )}

      {/* Side bar Menu */}
      <SideDrawer
        setActive={setActive}
        active={active}
        setActiveLink={setActiveLink}
        activeLink={activeLink}
      />
    </>
  );
}

export default Header;
