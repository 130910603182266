import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { secList } from "../../util/util";
import { Box, Button, Grid, Typography } from "@mui/material";
import GeneralInfoBed from "./bed/GeneralInfoBed";
import Img1 from "../../assets/images/prog_bed_img.svg";
import { ArrowDropDown } from "@mui/icons-material";
import RequirementsBed from "./bed/RequirementsBed";
import FeesBed from "./bed/FeesBed";
import "../../css/Programme.css";
import PaymentMethods from "./PaymentMethods";
import ProgSection from "./ProgSection";
import { getBedContent } from "../../redux/programme/ProgrammeAction";
import { useDispatch, useSelector } from "react-redux";

function BED() {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });

  const [section, setSection] = useState("general");
  const [sectionTitle, setSectionTitle] = useState("General info");

  const [show, setShow] = useState(false);

  const [active, setActive] = useState();

  const { loading, bed } = useSelector((state) => state.programme);

  console.log(bed);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBedContent());
  }, [dispatch]);

  return (
    <div style={{ padding: "1rem", width: "100%" }}>
      <ProgSection
        heading="Bachelors Degree in Education"
        image={Img1}
        setShow={setShow}
        setSection={setSection}
        section={section}
        setSectionTitle={setSectionTitle}
        show={show}
        sectionTitle={sectionTitle}
      />

      <Grid item sx={{ width: "100%" }}>
        {section === "general" ? (
          <GeneralInfoBed content={bed} loading={loading} />
        ) : section === "requirements" ? (
          <RequirementsBed content={bed} loading={loading} />
        ) : section === "fees" ? (
          <FeesBed content={bed} loading={loading} />
        ) : section === "payment" ? (
          <PaymentMethods content={bed} loading={loading} />
        ) : (
          <GeneralInfoBed content={bed} loading={loading} />
        )}
      </Grid>
    </div>
  );
}

export default BED;
