import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PageContainer from "../component/PageContainer";
import { NavLink, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { aboutUs, setIsLoading } from "../redux/aboutUs/aboutUsAction";
import Table from "../component/Table/Table";
import axios from "axios";


const Reports = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [activeYear, setActiveYear] = useState({ id: "7", name: "2024" });
  const [yearsList, setYearsList] = useState(null);



  useEffect(() => {
    const getYearsList = async () => {
      try {
        const response = await axios.get(`https://cms.nti.edu.ng/api/report/year`);
        setYearsList(response.data);
      } catch (error) {
        console.log("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };
    getYearsList();
  }, []);

  const classNameFunc = ({ isActive }) =>
    isActive ? "listItems listItems-active" : "listItems";

  const history = useLocation();
  const dispatch = useDispatch();

  const changeactiveYear = (type) => {
    setActiveYear((prevactiveYear) =>
      type === 0 ? prevactiveYear - 1 : prevactiveYear + 1
    );
  };

  useEffect(() => {
    dispatch(aboutUs());
  }, []);

  const selectedYearData = yearsList && yearsList[activeYear];

  return (
    <PageContainer>
      <Helmet>
        <title>Reports | National Teachers' Institute Kaduna</title>
      </Helmet>
      <div id="document">
        <h1 style={{ textAlign: "center", paddingTop: 40 }}>
          NTI REPORT SHEETS
        </h1>
        {isPhone ? (
          <div>
            {yearsList && (
              <Grid container>
                <Grid container>
                  <div className="mobile_arrow_wrapper">
                    <button
                      disabled={activeYear <= 0}
                      onClick={() => changeactiveYear(0)}
                      className="mobile-arrow-btn"
                    >
                      <ArrowBackIcon
                        style={{
                          color: activeYear <= 0 ? "#D0E3F2" : "#4F9D6B",
                          cursor: "pointer",
                        }}
                      />
                    </button>

                    <p
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#46C35F",
                      }}
                    >
                      {selectedYearData?.title}
                    </p>

                    <button
                      onClick={() => changeactiveYear(1)}
                      disabled={activeYear >= yearsList?.length - 1}
                      className="mobile-arrow-btn"
                    >
                      <ArrowForwardIcon
                        style={{
                          color:
                            activeYear >= yearsList?.length - 1
                              ? "#D0E3F2"
                              : "#4F9D6B",
                          cursor: "pointer",
                        }}
                      />
                    </button>
                  </div>
                  <Table activeYear={activeYear} />
                </Grid>
              </Grid>
            )}
          </div>
        ) : (
          <div style={{ background: "#fff", marginTop:'30px' }}>
            {yearsList && (
              <Grid
                sx={{ margin: "5% 0", padding: "0px 92px", display: "flex" }}
              >
                <Grid item style={{ marginTop: "1rem", flex: 1 }}>
                  <div id="floating-menu">
                    {yearsList.map((year, key) => (
                      <NavLink
                        className={classNameFunc({
                          isActive: activeYear === year,
                        })}
                        key={key}
                        onClick={() => setActiveYear(year)}
                      >
                        {`${year.name} TRAINING REPORTS`}
                      </NavLink>
                    ))}
                  </div>
                </Grid>
                <Grid item style={{ flex: 3 }} className="about-components">
                  <Table activeYear={activeYear} />
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </div>
    </PageContainer>
  );
};

export default Reports;
