import React, { useEffect, useState } from "react";
import PageContainer from "../component/PageContainer";
import "../css/Programme.css";
import Img1 from "../assets/images/prog_nti_prog_img.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NtiProgramme from "../component/programme/NtiProgramme";
import PGDE from "../component/programme/PGDE";
import BED from "../component/programme/BED";
import NCE from "../component/programme/NCE";
import ADE from "../component/programme/ADE";
import ADEE from "../component/programme/ADEE";
import PTTP from "../component/programme/PTTP";
import CPD from "../component/programme/CPD";
import CapacityBuilding from "../component/programme/CapacityBuilding";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import {
  getAdeContent,
  getAdeeContent,
  getBedContent,
  getCapacityContent,
  getNceContent,
  getPgdeContent,
  getProgrammeContent,
  getPttpContent,
} from "../redux/programme/ProgrammeAction";
import { useDispatch } from "react-redux";
import $ from "jquery";

function Programme() {
  const isPhone = useMediaQuery({ query: "(max-width: 812px)" });
  const [mobileActive, setMobileActive] = useState(0);

  // setting the active setion style
  const classNameFunc = ({ isActive }) =>
    isActive ? "listItems listItems-active" : "listItems";

  const history = useLocation();
  const dispatch = useDispatch();

  const leftLinks = [
    {
      title: "NTI Programmes",
      link: "/programmes/nti-programmes",
    },
    { title: "PGDE", link: "/programmes/pgde" },

    {
      title: "B.ED",
      link: "/programmes/bed",
    },
    { title: "NCE", link: "/programmes/nce" },
    { title: "ADE", link: "/programmes/ade" },
    { title: "ADEE", link: "/programmes/adee" },
    { title: "PTTP", link: "/programmes/pttp" },
    { title: "CPD", link: "/programmes/cpd" },
    { title: "Capacity Building", link: "/programmes/capacity-building" },
  ];

  const getSection = () => {
    switch (mobileActive) {
      case 0:
        return <NtiProgramme />;
      case 1:
        return <PGDE />;
      case 2:
        return <BED />;
      case 3:
        return <NCE />;
      case 4:
        return <ADE />;
      case 5:
        return <ADEE />;
      case 6:
        return <PTTP />;
      case 7:
        return <CPD />;
      case 8:
        return <CapacityBuilding />;
      default:
        <></>;
    }
  };

  const changeMobileActive = (type) => {
    // type can be 0 or 1 where 0 means previous and 1 means forward
    setMobileActive(type === 0 ? mobileActive - 1 : mobileActive + 1);
  };
  const [active, setActive] = useState("nti-programmes");

  // useEffect(() => {
  //   dispatch(getProgrammeContent());
  //   dispatch(getPgdeContent());
  //   dispatch(getNceContent());
  //   dispatch(getAdeContent());
  //   dispatch(getAdeeContent());
  //   dispatch(getPttpContent());
  //   dispatch(getCapacityContent());
  //   dispatch(getBedContent());
  // }, [dispatch]);

  useEffect(() => {
    setActive(history?.pathname?.split("/")[2] || "nti-programmes");
    if (history?.pathname?.split("/")[2]) {
      $("html, body").animate(
        {
          scrollTop: $("#document").offset().top,
        },
        1000
      );
    }
  }, [history.pathname]);

  return (
    <PageContainer>
      <Helmet>
        <title>Programme | National Teachers' Institute Kaduna</title>
      </Helmet>
      <div id="document">
        {isPhone ? (
          <>
            <div>
              <Grid container>
                <Grid container>
                  <div className="mobile_arrow_wrapper">
                    <button
                      disabled={mobileActive <= 0}
                      onClick={() => changeMobileActive(0)}
                      className="mobile-arrow-btn"
                    >
                      <ArrowBackIcon
                        style={{
                          color: mobileActive <= 0 ? "#D0E3F2" : "#4F9D6B",
                        }}
                      />
                    </button>

                    <p
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#46C35F",
                      }}
                    >
                      {leftLinks[mobileActive]?.title}
                    </p>

                    <button
                      onClick={() => changeMobileActive(1)}
                      disabled={mobileActive >= leftLinks.length - 1}
                      className="mobile-arrow-btn"
                    >
                      <ArrowForwardIcon
                        style={{
                          color:
                            mobileActive >= leftLinks.length - 1
                              ? "#D0E3F2"
                              : "#4F9D6B",
                        }}
                      />
                    </button>
                  </div>
                  {getSection()}
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <Grid
              container
              sx={{ margin: "5% 0", padding: "0px 92px", display: "flex" }}
            >
              <Grid item style={{ marginTop: "1rem", flex: 1 }}>
                <div id="floating-menu">
                  {leftLinks.map(({ link, title }, key) => {
                    return (
                      <NavLink
                        className={classNameFunc}
                        to={link}
                        key={key}
                        onClick={() => {
                          setActive(
                            history?.pathname?.split("/")[2] || "nti-programmes"
                          );
                        }}
                      >
                        {title}
                      </NavLink>
                    );
                  })}
                </div>
              </Grid>

              <Grid item style={{ flex: 4 }} className="about-components">
                {active === "nti-programmes" ? (
                  <NtiProgramme />
                ) : active === "pgde" ? (
                  <PGDE />
                ) : active === "bed" ? (
                  <BED />
                ) : active === "nce" ? (
                  <NCE />
                ) : active === "ade" ? (
                  <ADE />
                ) : active === "adee" ? (
                  <ADEE />
                ) : active === "pttp" ? (
                  <PTTP />
                ) : active === "cpd" ? (
                  <CPD />
                ) : (
                  <CapacityBuilding />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </PageContainer>
  );
}

export default Programme;
