import React, { useEffect, useState } from "react";
import Logo from "../assets/images/Logo.svg";
// import Mednu from "../assets/images/hamburger_menu.svg";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { Menu } from "@mui/icons-material";

function Navbar({ setActive, setActiveLink, activeLink }) {
  const isPhone = useMediaQuery({ query: "(max-width: 899px)" });
  const navigate = useNavigate();
  const location = useLocation();

  let links = [
    { title: "Home", link: "/" },
    {
      title: "About Us",
      link: "/about-us",
      subLink: [
        {
          title: "About NTI",
          link: "/about-us/about-nti",
        },
        { title: "Our Structure", link: "/about-us/structure" },

        {
          title: "Our Functions",
          link: "/about-us/functions",
        },
        { title: "NTI Management", link: "/about-us/management" },
        { title: "Governance", link: "/about-us/governance" },
        { title: "Our Achievements", link: "/about-us/achievement" },
        { title: "Specialized Centers", link: "/about-us/centers" },

        { title: "Zonal & State Offices", link: "/about-us/zonal-offices" },
      ],
    },
    {
      title: "Programmes",
      link: "/programmes",
      subLink: [
        {
          title: "NTI Programmes",
          link: "/programmes/nti-programmes",
        },
        {
          title: "Post Graduate Diploma in Education (PGDE)",
          link: "/programmes/pgde",
        },

        {
          title: "Bachelors Degree in Education (B.ED)",
          link: "/programmes/bed",
        },
        {
          title: "Nigeria Certificate In Education (NCE)",
          link: "/programmes/nce",
        },
        {
          title: "Advanced Diploma in Education(ADE)",
          link: "/programmes/ade",
        },
        {
          title: "Advanced Diploma in Environmental Education(ADEE)",
          link: "/programmes/adee",
        },
        {
          title: "Pivotal Teachers Training Programme (PTTP)",
          link: "/programmes/pttp",
        },
        {
          title: "The Capacity Building Programmes(CPD)",
          link: "/programmes/cpd",
        },
        { title: "Capacity Building", link: "/programmes/capacity-building" },
      ],
    },
    { title: "News & Media", link: "/news" },
    {
      title: "Servicom",
      link: "/servicom",
      subLink: [
        {
          title: "NTI Servicom",
          link: "/servicom/nti-servicom",
        },
        {
          title: "Contact",
          link: "/servicom/contact",
        },
      ],
    },
    { title: "Contact Us", link: "/contact-us" },
    { title: "Reports", link: "/reports" },
  ];
  const [scroll, setScroll] = useState(false);

  const handleScroll = (e) => {
    e.preventDefault();
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue) {
      if (scrollValue > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    }
  };
  // console.log(document.documentElement.scrollTop);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setActiveLink(location.pathname);
  }, []);
  return (
    <Box component="nav" className={scroll ? "nav_scroll" : "nav"} id="float">
      <Link
        to={"/"}
        id="logo"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <img src={Logo} alt="Logo" height="100%" />
      </Link>
      {isPhone ? (
        <Box
          sx={{
            display: "flex",

            width: "100%",
            justifyContent: "flex-end",
          }}
          className=""
        >
          <Menu
            className="menu"
            style={{
              cursor: "pointer",
              color: scroll ? "#000" : "#fff",
            }}
            onClick={() => setActive(true)}
          />
        </Box>
      ) : (
        /* <img
            src={Menu}
            alt="menu"
            style={{
              cursor: "pointer",
            }}
            onClick={() => setActive(true)}
          /> */
        <ul className="navbar_list">
          {links.map(({ link, title, subLink }, key) => (
            <nav
              key={key}
              className={
                activeLink === link
                  ? "dropdown dropdown-active"
                  : activeLink === link && scroll
                  ? "dropdown dropdown-scroll-active"
                  : scroll
                  ? "dropdown dropdown-scroll"
                  : "dropdown"
              }
            >
              <li
                onClick={() => {
                  setActiveLink(link);
                  navigate(link);
                }}
              >
                {title}
              </li>
              {subLink && (
                <div className="dropdown-content">
                  {subLink?.map(({ title, link }, key) => (
                    <Link key={key} to={link}>
                      {title}
                    </Link>
                  ))}
                </div>
              )}
            </nav>
          ))}
        </ul>
      )}
    </Box>
  );
}

export default Navbar;
