import { Drawer } from "@mui/material";
import React, { useState } from "react";
import Logo from "../assets/images/Logo.svg";
import Close from "../assets/icon/close.svg";
import Facebook from "../assets/icon/facebook_green_icon.svg";
import Twitter from "../assets/icon/twitter_green_icon.svg";
import Linkedln from "../assets/icon/linkedIn_green_icon.svg";
import Youtube from "../assets/icon/youtube_green_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";

function SideDrawer({ setActive, setActiveLink, active, activeLink }) {
  const navigate = useNavigate();
  let links = [
    { title: "Home", link: "/" },
    { title: "About Us", link: "/about-us" },
    { title: "Programmes", link: "/programmes" },
    { title: "News & Media", link: "/news" },
    { title: "Servicom", link: "/servicom" },
    { title: "Contact Us", link: "/contact-us" },
    { title: "Reports", link: "/reports" },

  ];

  const social = [
    {
      title: "Twitter icon",
      icon: Twitter,
    },
    {
      title: "Facebook icon",
      icon: Facebook,
    },
    {
      title: "Youtube icon",
      icon: Youtube,
    },
    {
      title: "Linkedln icon",
      icon: Linkedln,
    },
  ];

  return (
    <Drawer
      className="drawer"
      anchor="left"
      open={active}
      onClose={() => setActive(false)}
      transitionDuration={1500}
    >
      <div style={{ width: "100%", height: "80%" }} role="presentation">
        <div className="side_bar_logo_wrapper">
          <img src={Logo} alt="Logo" />
          <img src={Close} alt="close" onClick={() => setActive(false)} />
        </div>

        <div className="menu_list_items_wrapper">
          <ul className="sidebar_navbar_list">
            {links.map(({ link, title }, key) => (
              <li
                key={key}
                onClick={() => {
                  setActiveLink(link);
                  navigate(link);
                }}
                className="sidebar_navbar_list_items"
                style={{
                  color: activeLink === link ? "#46C35F" : "#000",
                  cursor: "pointer",
                }}
              >
                {title}
              </li>
            ))}
          </ul>

          <div className="footer_social_icons">
            {social.map((icon, key) => (
              <img
                src={icon.icon}
                alt={icon.title}
                key={key}
                style={{ marginRight: "24px" }}
              />
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default SideDrawer;
