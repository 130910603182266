import React from "react";
import { useMediaQuery } from "react-responsive";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { secList } from "../../util/util";

function ProgSection({
  heading,
  image,
  setShow,
  show,
  setSection,
  setSectionTitle,
  sectionTitle,
  section,
}) {
  const isPhone = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <>
      <h4 style={{ fontSize: "20px" }}>{heading}</h4>
      {/* <img src={image} alt="students" className="about_section_images" /> */}
      {isPhone ? (
        <div
          style={{
            position: "relative",
            width: "100%",
            background: "#00C853",
            height: "44px",
            padding: "0rem 1rem",
            // zIndex: 0,
          }}
          onClick={() => setShow(!show)}
        >
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              color: "#fff",
            }}
          >
            <p>{sectionTitle}</p>
          </div>
          <ArrowDropDown
            onClick={() => setShow(!show)}
            color="#fff"
            sx={{
              position: "absolute",
              right: ".3rem",
              top: ".1rem",
              color: "#fff",
              width: "40px",
              height: "40px",
            }}
          />
          <div
            style={{
              position: "absolute",
              display: show ? "flex" : "none",
              alignItems: "flex-start",
              padding: "1rem",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              height: "auto",
              color: "#000",
              bottom: "-12rem",
              left: "0px",
              background: "#fff",
              transition: "ease-in-out 0.9s",
              border: "2px solid #F6F6F6",
              boxShadow: " 0px 3px 6px rgba(0, 0, 0, 0.12)",
              borderBottomRightRadius: "20px",
              borderBottomLeftRadius: "20px",
              zIndex: 50,
            }}
          >
            {secList.map(({ name, section }, key) => (
              <Typography
                onClick={() => {
                  setSection(section);
                  setSectionTitle(name);
                }}
                key={key}
                sx={{
                  marginBottom: "1rem",
                }}
                className="pgde_dropdown_lists"
              >
                {name}
              </Typography>
            ))}
          </div>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            background: "#F6F6F6",
            height: "auto",
          }}
        >
          {secList.map((list, key) => (
            <button
              key={key}
              style={{
                width: "50%",
                height: "44px",
                color: list.section === section ? "#fff" : "#2E2E2E",
                background: list.section === section ? "#00C853" : "#F6F6F6",
                border: "none",
                outline: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                setSection(list.section);
                setSectionTitle(list.name);
              }}
            >
              {list.name}
            </button>
          ))}
        </Box>
      )}
    </>
  );
}

export default ProgSection;
