import * as ProgTypes from "./ProgrammeTypes";

const initialState = {
  isLoading: false,
  programme: {},
  pgde: {},
  nce: {},
  bed: {},
  pttp: {},
  ade: {},
  adee: {},
  capacity: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ProgTypes.IS_PROG_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ProgTypes.GET_PROGRAM_MAIN_CONTENT:
      return {
        ...state,
        programme: action.payload,
      };
    case ProgTypes.GET_PGDE_PAGE_CONTENT:
      return {
        ...state,
        pgde: action.payload,
      };
    case ProgTypes.GET_NCE_PAGE_CONTENT:
      return {
        ...state,
        nce: action.payload,
      };
    case ProgTypes.GET_ADE_PAGE_CONTENT:
      return {
        ...state,
        ade: action.payload,
      };
    case ProgTypes.GET_ADEE_PAGE_CONTENT:
      return {
        ...state,
        adee: action.payload,
      };
    case ProgTypes.GET_PTTP_PAGE_CONTENT:
      return {
        ...state,
        pttp: action.payload,
      };
    case ProgTypes.GET_CAPACITY_PAGE_CONTENT:
      return {
        ...state,
        capacity: action.payload,
      };
    case ProgTypes.GET_BED_PAGE_CONTENT:
      return {
        ...state,
        bed: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
